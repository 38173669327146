// DisputeResolutionModal.js
import React from 'react';
import Modal from './Modal';
import './DisputeResolutionModal.css';

const DisputeResolutionModal = ({ 
    isOpen, 
    onClose, 
    disputedEvent, 
    resolvedScores, 
    setResolvedScores, 
    handleDisputeResolution 
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
           
                <div className="dispute-resolution-modal">
                    <h2 className="modal-title">Résolution du litige</h2>
                    {disputedEvent && (
                        <div className="dispute-details">
                            <div className="event-header">
                                <h3>{disputedEvent.title}</h3>
                                <p className="event-date">
                                    Date: {new Date(disputedEvent.date).toLocaleDateString()}
                                </p>
                            </div>
                            
                           <div className="disputed-scores">
                                <h4>Scores soumis en litige :</h4>
                                {disputedEvent?.tempScores && (
                                    <div className="scores-dispute-container">
                                        {/* Score du premier capitaine */}
                                        <div className="team-dispute">
                                            <div className="score-details">
                                                <div className="teams-score-display">
                                                    <div className="team-column">
                                                        <h5>Équipe Noire</h5>
                                                        <p className="score-value">
                                                            {disputedEvent.tempScores.find(s => s.teamIndex === 0)?.score?.team1 || 0}
                                                        </p>
                                                    </div>
                                                    <span className="score-separator">-</span>
                                                    <div className="team-column">
                                                        <h5>Équipe Bleue</h5>
                                                        <p className="score-value">
                                                            {disputedEvent.tempScores.find(s => s.teamIndex === 0)?.score?.team2 || 0}
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className="submitted-by">
                                                    Soumis par : {disputedEvent.tempScores.find(s => s.teamIndex === 0)?.submittedBy?.pseudo || 'Non spécifié'}
                                                    {' '}(Capitaine de l'équipe Noire)
                                                </p>
                                            </div>
                                        </div>

                                        {/* Score du deuxième capitaine */}
                                        <div className="team-dispute">
                                            <div className="score-details">
                                                <div className="teams-score-display">
                                                    <div className="team-column">
                                                        <h5>Équipe Noire</h5>
                                                        <p className="score-value">
                                                            {disputedEvent.tempScores.find(s => s.teamIndex === 1)?.score?.team1 || 0}
                                                        </p>
                                                    </div>
                                                    <span className="score-separator">-</span>
                                                    <div className="team-column">
                                                        <h5>Équipe Bleue</h5>
                                                        <p className="score-value">
                                                            {disputedEvent.tempScores.find(s => s.teamIndex === 1)?.score?.team2 || 0}
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className="submitted-by">
                                                Soumis par : {disputedEvent.tempScores.find(s => s.teamIndex === 1)?.submittedBy?.pseudo || 'Non spécifié'}
                                                {' '}(Capitaine de l'équipe Bleue)
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            

                            <div className="team-scores">
                                <div className="team">
                                    <h4 className="team-title">Équipe Noire</h4>
                                    <div className="score-input-container">
                                        <input
                                            type="number"
                                            min="0"
                                            className="score-input"
                                            value={resolvedScores.team1}
                                            onChange={(e) => setResolvedScores({
                                                ...resolvedScores,
                                                team1: e.target.value
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="team">
                                    <h4 className="team-title">Équipe Bleue</h4>
                                    <div className="score-input-container">
                                        <input
                                            type="number"
                                            min="0"
                                            className="score-input"
                                            value={resolvedScores.team2}
                                            onChange={(e) => setResolvedScores({
                                                ...resolvedScores,
                                                team2: e.target.value
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="action-buttons">
                                <button 
                                    className="validate-button"
                                    onClick={() => handleDisputeResolution(disputedEvent._id)}
                                >
                                    Valider les scores
                                </button>
                            </div>
                        </div>
                    )}
                </div>
           
        </Modal>
    );
};

export default DisputeResolutionModal;