// StatsCard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './StatsCard.css';

const StatsCard = ({ user }) => {
  const [latestStats, setLatestStats] = useState({
    elo: user?.elo || 1000,
    matchesPlayed: 0,
    wins: 0,
    losses: 0
  });

  useEffect(() => {
    const fetchLatestStats = async () => {
      try {
        // Récupérer les événements terminés où l'utilisateur a participé
        const response = await axios.get(`http://localhost:5000/api/events/user-stats/${user._id}`);
        const stats = response.data;

        // Mettre à jour les statistiques
        setLatestStats({
          elo: stats.currentElo || user?.elo || 1000,
          matchesPlayed: stats.matchesPlayed || 0,
          wins: stats.wins || 0,
          losses: stats.losses || 0
        });
      } catch (error) {
        console.error('Erreur lors de la récupération des statistiques:', error);
      }
    };

    if (user?._id) {
      fetchLatestStats();
    }
  }, [user]);

  return (
    <div className="card stats-card">
      <div className="card-header">
        <h2 className="card-title">
          <i className="mdi mdi-chart-line"></i> Statistiques
        </h2>
      </div>
      <div className="stats-content">
        <div className="stat-item">
          <div className="stat-icon">
            <i className="mdi mdi-trophy"></i>
          </div>
          <div className="stat-info">
            <span className="stat-label">Points ELO</span>
            <span className="stat-value">{latestStats.elo}</span>
          </div>
        </div>

        <div className="stat-item">
          <div className="stat-icon">
            <i className="mdi mdi-soccer-field"></i>
          </div>
          <div className="stat-info">
            <span className="stat-label">Matchs joués</span>
            <span className="stat-value">{latestStats.matchesPlayed}</span>
          </div>
        </div>

        <div className="stat-item">
          <div className="stat-icon">
            <i className="mdi mdi-check-circle"></i>
          </div>
          <div className="stat-info">
            <span className="stat-label">Victoires</span>
            <span className="stat-value">{latestStats.wins}</span>
          </div>
        </div>

        <div className="stat-item">
          <div className="stat-icon">
            <i className="mdi mdi-close-circle"></i>
          </div>
          <div className="stat-info">
            <span className="stat-label">Défaites</span>
            <span className="stat-value">{latestStats.losses}</span>
          </div>
        </div>

        <div className="stat-item">
          <div className="stat-icon">
            <i className="mdi mdi-chart-bar"></i>
          </div>
          <div className="stat-info">
            <span className="stat-label">Ratio V/D</span>
            <span className="stat-value">
              {latestStats.wins && latestStats.losses 
                ? (latestStats.wins / (latestStats.wins + latestStats.losses)).toFixed(2)
                : '0.00'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;