// src/components/UserSidebar.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import './Sidebar.css';

const UserSidebar = ({ onShowDashboard, onShowSubscriptions, onShowFormulas, onShowEvents, onShowProfil }) => {
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    const [activeItem, setActiveItem] = useState('dashboard');

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const handleItemClick = (item, callback) => {
        setActiveItem(item);
        callback();
    };

    return (
        <aside className="sidebar">
            <div className="logo">Sport-Entreprise</div>
            <nav>
                <button 
                    onClick={() => handleItemClick('dashboard', onShowDashboard)} 
                    className={`nav-item ${activeItem === 'dashboard' ? 'active' : ''}`}
                >
                    <i className="mdi mdi-view-dashboard"></i>Tableau de bord
                </button>
                <button 
                    onClick={() => handleItemClick('formulas', onShowFormulas)} 
                    className={`nav-item ${activeItem === 'formulas' ? 'active' : ''}`}
                >
                    <i className="mdi mdi-ticket-percent"></i>Formules
                </button>
                <button 
                    onClick={() => handleItemClick('events', onShowEvents)} 
                    className={`nav-item ${activeItem === 'events' ? 'active' : ''}`}
                >
                    <i className="mdi mdi-calendar"></i>Événements
                </button>
                <button 
                    onClick={() => handleItemClick('profil', onShowProfil)} 
                    className={`nav-item ${activeItem === 'profil' ? 'active' : ''}`}
                >
                    <i className="mdi mdi-account"></i>Mon Profil
                </button>
            </nav>

         
            <button onClick={handleLogout} className="logout-btn">
                <i className="mdi mdi-logout"></i>Déconnexion
            </button>
        </aside>
    );
};

export default UserSidebar;