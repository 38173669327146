import React from 'react';
import ReactDOM from 'react-dom/client'; // Importer createRoot
import App from './App'; // Importer le composant principal de l'application
import { AuthProvider } from './AuthContext'; // Importer le contexte d'authentification
import './index.css'; // Importer les styles CSS

// Créer un point d'entrée pour le rendu
const root = ReactDOM.createRoot(document.getElementById('root'));

// Rendre l'application
root.render(
  <React.StrictMode>
    <AuthProvider> {/* Envelopper l'application dans le AuthProvider */}
      <App />
    </AuthProvider>
  </React.StrictMode>
);
