import React, { useState } from 'react';
import './ScoreModal.css';

const ScoreModal = ({ show, event, user, onClose, onScoreSubmit }) => {
  const [scores, setScores] = useState({
    team1: 0,
    team2: 0
  });
  const [error, setError] = useState(null);

  if (!show) return null;


  // Dans le composant ScoreModal, modifiez la fonction handleSubmit
const handleSubmit = async (e) => {
  e.preventDefault();
  
  try {
    console.log('Données du modal:', { event, user, scores });

    // Création d'un nouveau tempScore avec une structure cohérente
    const newTempScore = {
      eventId: event._id,
      score: {
        team1: parseInt(scores.team1),
        team2: parseInt(scores.team2)
      },
      status: 'PENDING',
      submittedBy: {
        _id: user._id,
        nom: user.nom
      },
      teamIndex: event.teams.findIndex(team => 
        team.players.some(player => player.user._id === user._id)
      ),
      createdAt: new Date().toISOString()
    };

    const updatedEvent = {
      ...event,
      teams: [
        { ...event.teams[0], score: parseInt(scores.team1) },
        { ...event.teams[1], score: parseInt(scores.team2) }
      ],
      tempScores: [
        ...(event.tempScores || []),
        newTempScore
      ]
    };

    console.log('ScoreModal - Données avant soumission:', updatedEvent);
    await onScoreSubmit(updatedEvent);
    console.log('ScoreModal - Score soumis avec succès');
    
    onClose();
  } catch (error) {
    console.error('ScoreModal - Erreur détaillée:', error);
    setError(error.message || 'Erreur lors de la soumission du score');
  }
};

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>Ajouter le score</h3>
          <button onClick={onClose} className="close-btn">
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        
        {error && <div className="error-message">{error}</div>}
        
        <form onSubmit={handleSubmit} className="score-form">
          <div className="teams-score-container">
            <div className="team-score-input">
              <label>Équipe Noire</label>
              <input
                type="number"
                min="0"
                value={scores.team1}
                onChange={(e) => setScores(prev => ({ ...prev, team1: e.target.value }))}
                required
              />
            </div>
            
            <div className="score-separator">-</div>
            
            <div className="team-score-input">
              <label>Équipe Bleue</label>
              <input
                type="number"
                min="0"
                value={scores.team2}
                onChange={(e) => setScores(prev => ({ ...prev, team2: e.target.value }))}
                required
              />
            </div>
          </div>
          
          <div className="modal-footer">
            <button type="submit" className="submit-btn">
              Valider le score
            </button>
            <button type="button" className="cancel-btn" onClick={onClose}>
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScoreModal;