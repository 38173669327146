// EventsList.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import './EventsList.css';

const EventsList = ({ refreshNotifications }) => {
  const { user } = useAuth();
  const [events, setEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [eventSubscriptions, setEventSubscriptions] = useState({});
  const [selectedFilter, setSelectedFilter] = useState('all'); // 'all', 'football', 'padel'
  const [showSubscribersModal, setShowSubscribersModal] = useState(false);
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
 
  const sportImages = {
    Football: require('../assets/images/football-image.jpg'),
    Padel: require('../assets/images/padel-image.jpg'),
  };

  useEffect(() => {
    fetchEvents();
    if (user?._id) {
      fetchUserSubscriptions();
    }
  }, [user?._id]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/events');
      
      const eventsWithDetails = await Promise.all(
        response.data.map(async (event) => {
          const subscriptionCount = await axios.get(
            `http://localhost:5000/api/event-subscriptions/count/${event._id}`
          );
          return {
            ...event,
            subscribersCount: subscriptionCount.data.count
          };
        })
      );

      // Séparer les événements à venir et passés
      const upcoming = eventsWithDetails.filter(
        event => event.status === 'À venir' || event.status === 'En cours'
      );
      const past = eventsWithDetails.filter(
        event => event.status === 'Terminé'
      );

      setEvents(upcoming);
      setPastEvents(past);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des événements:', error);
      setError('Erreur lors de la récupération des événements');
      setLoading(false);
    }
  };

  const fetchUserSubscriptions = async () => {
    try {
      const promises = events.map(event =>
        isUserSubscribedToEvent(event._id)
      );
      const results = await Promise.all(promises);
      const subscriptions = {};
      events.forEach((event, index) => {
        subscriptions[event._id] = results[index];
      });
      setEventSubscriptions(subscriptions);
    } catch (error) {
      console.error('Erreur lors de la récupération des souscriptions:', error);
    }
  };

  const isUserSubscribedToEvent = async (eventId) => {
    try {
      const response = await axios.get(`http://localhost:5000/api/event-subscriptions/check`, {
        params: {
          userId: user._id,
          eventId: eventId
        }
      });
      return response.data.isSubscribed;
    } catch (error) {
      return false;
    }
  };

  const handleEventRegistration = async (eventId) => {
    try {
      await axios.post('http://localhost:5000/api/event-subscriptions', {
        user: user._id,
        event: eventId,
        status: 'Validé',
      });
      await fetchEvents();
      await fetchUserSubscriptions();
    } catch (error) {
      setError('Erreur lors de l\'inscription à l\'événement');
    }
  };

  const handleEventUnsubscription = async (eventId) => {
    try {
      await axios.delete(`http://localhost:5000/api/event-subscriptions/${user._id}/${eventId}`);
      await fetchEvents();
      await fetchUserSubscriptions();
    } catch (error) {
      setError('Erreur lors de la désinscription de l\'événement');
    }
  };


  const filteredEvents = events.filter(event => {
    if (selectedFilter === 'all') return true;
    return event.sport.name.toLowerCase() === selectedFilter;
  });

  const filteredPastEvents = pastEvents.filter(event => {
    if (selectedFilter === 'all') return true;
    return event.sport.name.toLowerCase() === selectedFilter;
  });

  if (loading) return <div className="loading">Chargement...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="events-container">
      <div className="events-header">
        <h2><i className="mdi mdi-calendar"></i> Événements</h2>
        <div className="filters">
          <button 
            className={`filter-btn ${selectedFilter === 'all' ? 'active' : ''}`}
            onClick={() => setSelectedFilter('all')}
          >
            Tous
          </button>
          <button 
            className={`filter-btn ${selectedFilter === 'football' ? 'active' : ''}`}
            onClick={() => setSelectedFilter('football')}
          >
            <i className="mdi mdi-soccer"></i> Football
          </button>
          <button 
            className={`filter-btn ${selectedFilter === 'padel' ? 'active' : ''}`}
            onClick={() => setSelectedFilter('padel')}
          >
            <i className="mdi mdi-tennis"></i> Padel
          </button>
        </div>
      </div>

      <div className="events-section">
        <h3>Événements à venir</h3>
        <div className="events-grid">
          {filteredEvents.map(event => (
            <div key={event._id} className="event-card">
              <img 
                src={sportImages[event.sport.name]} 
                alt={event.sport.name} 
                className="event-image" 
              />
              <div className="event-content">
                <h4>{event.title}</h4>
                <div className="event-details">
                  <p><i className="mdi mdi-calendar"></i> {formatDate(event.date)}</p>
                  <p><i className="mdi mdi-map-marker"></i> {event.location}</p>
                  <p>
                    <i className="mdi mdi-account-group"></i> 
                    {event.subscribersCount} / {event.maxParticipants} participants
                  </p>
                </div>
                <div className="event-actions">
                  {eventSubscriptions[event._id] ? (
                    <button 
                      className="unsubscribe-btn"
                      onClick={() => handleEventUnsubscription(event._id)}
                    >
                      Se désinscrire
                    </button>
                  ) : (
                    <button 
                      className="subscribe-btn"
                      onClick={() => handleEventRegistration(event._id)}
                      disabled={event.subscribersCount >= event.maxParticipants}
                    >
                      {event.subscribersCount >= event.maxParticipants ? 
                        'Complet' : 'S\'inscrire'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="events-section">
        <h3>Événements passés</h3>
        <div className="events-grid">
          {filteredPastEvents.map(event => (
            <div key={event._id} className="event-card past">
              <img 
                src={sportImages[event.sport.name]} 
                alt={event.sport.name} 
                className="event-image" 
              />
              <div className="event-content">
                <h4>{event.title}</h4>
                <div className="event-details">
                  <p><i className="mdi mdi-calendar"></i> {formatDate(event.date)}</p>
                  <p><i className="mdi mdi-map-marker"></i> {event.location}</p>
                  <p>
                    <i className="mdi mdi-account-group"></i> 
                    {event.subscribersCount} participants
                  </p>
                </div>
                {event.teams && (
                  <div className="event-result">
                    <p>Score final: {event.teams[0].score} - {event.teams[1].score}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>


    </div>
  );
};

export default EventsList;