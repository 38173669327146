import React, { useState, useEffect } from 'react';
import './FormulasManager.css';
import axios from 'axios';

const FormulasManager = () => {
  const [formulas, setFormulas] = useState({}); // Formules groupées par sport
  const [sports, setSports] = useState([]); // Liste des sports
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [selectedFormula, setSelectedFormula] = useState(null);

  // Fetch data when component mounts or dependencies change
  useEffect(() => {
    const fetchFormulasAndSports = async () => {
      try {
        // Récupérer les formules
        const response = await axios.get('http://localhost:5000/api/formulas');
        console.log("Formules récupérées:", response.data);

        // Regrouper les formules par sport
        const groupedFormulas = {};
        response.data.forEach((formula) => {
          const sportId = formula.sport._id;
          if (!groupedFormulas[sportId]) {
            groupedFormulas[sportId] = [];
          }
          groupedFormulas[sportId].push(formula);
        });

        console.log("Formules groupées par sport:", groupedFormulas);
        setFormulas(groupedFormulas);

        // Récupérer les sports
        const sportsResponse = await axios.get('http://localhost:5000/api/sports');
        setSports(sportsResponse.data);
      } catch (err) {
        console.error("Erreur lors de la récupération des formules ou des sports", err);
      }
    };

    let isSubscribed = true;

    fetchFormulasAndSports().then(() => {
      if (!isSubscribed) return;
    }).catch(console.error);

    return () => {
      isSubscribed = false;
    };
  }, []);

  const openModal = (type, formula = null) => {
    setModalType(type);
    setSelectedFormula(formula);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedFormula(null);
  };

  const handleToggleActive = async (formulaId) => {
    // Trouve la formule correspondante à l'aide de formulaId
    const allFormulas = Object.values(formulas).flat();
    const formula = allFormulas.find(f => f._id === formulaId);
    
    if (!formula) return;
  
    const updatedIsActive = !formula.isActive; // inverse la valeur de 'isActive'
  
    try {
      const response = await fetch(`http://localhost:5000/api/formulas/${formulaId}/activate`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ isActive: updatedIsActive }),
      });
  
      if (response.ok) {
        // Met à jour l'état local pour refléter les modifications
        setFormulas(prevFormulas => {
          const updatedFormulas = { ...prevFormulas };
          Object.keys(updatedFormulas).forEach(sportId => {
            updatedFormulas[sportId] = updatedFormulas[sportId].map(f =>
              f._id === formulaId ? { ...f, isActive: updatedIsActive } : f
            );
          });
          return updatedFormulas;
        });
      } else {
        const errorData = await response.json();
        console.error('Erreur lors de la mise à jour de la formule:', errorData.message);
      }
    } catch (error) {
      console.error('Erreur lors de la requête:', error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    const sportId = e.target.sport.value;
    if (!sportId) {
      console.error("Aucun sport sélectionné");
      return;
    }

    const formData = {
      title: e.target.name.value,
      price: parseFloat(e.target.price.value.replace('€', '').trim()),
      expiryDate: e.target.expiryDate.value,
      sport: sportId,
      isActive: true,
    };

    try {
      if (modalType === "add") {
        const response = await axios.post('http://localhost:5000/api/formulas', formData);
        
        if (response.data) {
          setFormulas(prevFormulas => {
            const newSportId = response.data.sport._id || response.data.sport;
            return {
              ...prevFormulas,
              [newSportId]: [...(prevFormulas[newSportId] || []), response.data]
            };
          });
        }
      } else if (modalType === "edit" && selectedFormula) {
        const response = await axios.put(`http://localhost:5000/api/formulas/${selectedFormula._id}`, formData);
        
        if (response.data) {
          setFormulas(prevFormulas => {
            const updatedFormulas = { ...prevFormulas };
            const sportId = selectedFormula.sport._id || selectedFormula.sport;
            updatedFormulas[sportId] = updatedFormulas[sportId].map(formula =>
              formula._id === selectedFormula._id ? response.data : formula
            );
            return updatedFormulas;
          });
        }
      }
      
      // Fetch data again to update the state
      const updateData = async () => {
        try {
          const response = await axios.get('http://localhost:5000/api/formulas');
          const data = response.data;
          const groupedFormulas = {};
          data.forEach((formula) => {
            const sportId = formula.sport._id;
            if (!groupedFormulas[sportId]) {
              groupedFormulas[sportId] = [];
            }
            groupedFormulas[sportId].push(formula);
          });
          setFormulas(groupedFormulas);
        } catch (err) {
          console.error("Erreur lors de la récupération des formules", err);
        }
      };
      
      updateData();
      
      closeModal();
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire', 
        error.response ? error.response.data : 'Pas de réponse du serveur', 
        error.message
      );
    }
  };

  const renderFormulas = (sportId) => {
    const sportFormulas = formulas[sportId] || [];
    
    return sportFormulas.length > 0 ? (
      sportFormulas.map(formula => {
        if (!formula || !formula.sport) {
          console.warn("Formule invalide:", formula);
          return null;
        }

        const expiryDate = formula.expiryDate 
          ? (() => {
              const [day, month, year] = formula.expiryDate.split('/');
              return new Date(`${year}-${month}-${day}`);
            })()
          : new Date();

        return (
          <div key={formula._id} className="formula-card">
            <div className="card-actions">
              <button className="btn btn-outline" onClick={() => openModal("edit", formula)}>
                <i className="mdi mdi-pencil"></i>
              </button>
              <button className="btn btn-outline" onClick={() => handleDeleteFormula(formula._id)}>
                <i className="mdi mdi-delete"></i>
              </button>
            </div>
            <div className="formula-header">
              <div>
                <h2 className="formula-title">{formula.title}</h2>
                <div className="formula-price">{formula.price}</div>
              </div>
            </div>
            <div className="formula-expiry">
              <i className="mdi mdi-clock-outline"></i> Expire le: {expiryDate.toLocaleDateString()}
            </div>
            <div className="status-toggle">
              <span>Activer la formule</span>
              <label className="switch">
                <input 
                  type="checkbox" 
                  checked={formula.isActive} 
                  onChange={() => handleToggleActive(formula._id)}
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        );
      }).filter(Boolean)
    ) : (
      <p>Aucune formule disponible pour ce sport.</p>
    );
  };

  const handleDeleteFormula = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/api/formulas/${id}`);
      setFormulas(prevFormulas => {
        const updatedFormulas = { ...prevFormulas };
        Object.keys(updatedFormulas).forEach(sportId => {
          updatedFormulas[sportId] = updatedFormulas[sportId].filter(formula => formula._id !== id);
        });
        return updatedFormulas;
      });
      
      // Fetch data again to update the state
      const updateData = async () => {
        try {
          const response = await axios.get('http://localhost:5000/api/formulas');
          const data = response.data;
          const groupedFormulas = {};
          data.forEach((formula) => {
            const sportId = formula.sport._id;
            if (!groupedFormulas[sportId]) {
              groupedFormulas[sportId] = [];
            }
            groupedFormulas[sportId].push(formula);
          });
          setFormulas(groupedFormulas);
        } catch (err) {
          console.error("Erreur lors de la récupération des formules", err);
        }
      };
      
      updateData();
    } catch (err) {
      console.error("Erreur lors de la suppression de la formule", err);
    }
  };

  return (
    <div className="dashboard">
      <main className="main-content">
        <div className="header">
          <h1>Gestion des Formules</h1>
          <button className="btn btn-primary2" onClick={() => openModal("add")}>
            <i className="mdi mdi-plus"></i> Nouvelle formule
          </button>
        </div>
        {Object.keys(formulas).length > 0 ? (
          Object.keys(formulas).map(sportId => {
            const sport = sports.find(sport => sport._id === sportId);
            
            return sport ? (
              <div key={sportId} className="category-section">
                <h2 className="category-title">{sport.name}</h2>
                <div className="formula-cards">{renderFormulas(sportId)}</div>
              </div>
            ) : null;
          }).filter(Boolean)
        ) : (
          <p>Aucune formule disponible pour le moment.</p>
        )}
      </main>

      {modalVisible && (
        <div id="formulaModal" className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>{modalType === "add" ? "Nouvelle formule" : "Modifier la formule"}</h2>
              <span className="close-modal" onClick={closeModal}>&times;</span>
            </div>
            <form className="modal-form" onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label htmlFor="name">Nom de la formule</label>
                <input
                  type="text"
                  id="name"
                  defaultValue={selectedFormula ? selectedFormula.title : ""}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="price">Prix</label>
                <input
                  type="text"
                  id="price"
                  defaultValue={selectedFormula ? `${selectedFormula.price}` : ""}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="expiryDate">Date d'expiration</label>
                <input
                  type="date"
                  id="expiryDate"
                  defaultValue={
                    selectedFormula && selectedFormula.expiryDate
                      ? selectedFormula.expiryDate.split('/').reverse().join('-')
                      : ""
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="sport">Sport</label>
                <select 
                  id="sport" 
                  defaultValue={
                    selectedFormula 
                      ? (selectedFormula.sport?._id || selectedFormula.sport)
                      : (sports.length > 0 ? sports._id : "")
                  } 
                  required
                >
                  {sports.map(sport => (
                    <option key={sport._id} value={sport._id}>
                      {sport.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  {modalType === "add" ? "Ajouter" : "Sauvegarder"}
                </button>
                {/*<button type="button" className="btn btn-secondary" onClick={closeModal}>
                  Annuler
                </button>*/}
              </div>
            </form>

          </div>
        </div>
      )}
    </div>
  );
};

export default FormulasManager;