import React from 'react';
import { Link } from 'react-router-dom';
import './Accueil.css';

const Accueil = () => {
    return (
        <div className="accueil-container">
            <div className="hero-section">
                <h1>Bienvenue sur Sport-Entreprise</h1>
                <div className="subtitle">
                    Votre plateforme de gestion sportive intelligente
                </div>
                
                <div className="features-grid">
                    <div className="feature-card">
                        <i className="fas fa-futbol"></i>
                        <h3>Football</h3>
                        <p>Réservez vos créneaux et gérez vos adhésions</p>
                    </div>
                    
                    <div className="feature-card">
                        <i className="fas fa-table-tennis"></i>
                        <h3>Padel</h3>
                        <p>Organisez vos parties et suivez vos réservations</p>
                    </div>
                </div>

                <Link to="/auth" className="cta-button">
                    Commencer maintenant
                </Link>
            </div>
        </div>
    );
};

export default Accueil;