// src/components/EventManagement.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EventCard from './EventCard';
import Modal from './Modal';
import './EventManagement.css';
import TeamCompositionWithScore from './TeamCompositionWithScore';
import DisputeResolutionModal from './DisputeResolutionModal';

const EventManagement = () => {
    const [events, setEvents] = useState([]);
    const [sports, setSports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [filter, setFilter] = useState({
        title: '',
        status: '',
        sport: '',
    });
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [newEvent, setNewEvent] = useState({
        title: '',
        date: '',
        location: '',
        status: '',
        sport: '',
    });

    const [editEvent, setEditEvent] = useState(null); 
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedEventSubscribers, setSelectedEventSubscribers] = useState([]);

    const [isCaptainSelectionMode, setIsCaptainSelectionMode] = useState(false);
    const [selectedCaptains, setSelectedCaptains] = useState({ team1: null, team2: null });
    const [captainsLocked, setCaptainsLocked] = useState(false); 

    const [showDisputeModal, setShowDisputeModal] = useState(false);
    const [disputedEvent, setDisputedEvent] = useState(null);
    const [resolvedScores, setResolvedScores] = useState({ team1: 0, team2: 0 });
    

   

    const fetchEvents = async () => {
        try {
            const response = await axios.get('http://localhost:5000/api/events');
            
            // Récupérer les événements avec leurs détails complets
            const eventsWithDetails = await Promise.all(
                response.data.map(async (event) => {
                    // Récupérer le nombre d'inscrits
                    const subscriptionCount = await axios.get(
                        `http://localhost:5000/api/event-subscriptions/count/${event._id}`
                    );

                    // Récupérer les tempScores
                    const tempScores = await axios.get(
                        `http://localhost:5000/api/events/${event._id}/temp-scores`
                    );

                    // Vérifier si l'événement a des litiges
                    const hasDispute = tempScores.data.some(score => score.status === 'DISPUTED');

                    return {
                        ...event,
                        sportName: event.sport.name,
                        subscribersCount: subscriptionCount.data.count,
                        tempScores: tempScores.data,
                        hasDispute,
                        tempScoresLength: tempScores.data.length
                    };
                })
            );

            setEvents(eventsWithDetails);
            console.log("Événements récupérés avec litiges:", eventsWithDetails);
        } catch (err) {
            setError('Erreur lors de la récupération des événements');
            console.error("Erreur de récupération :", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
      
        const fetchSports = async () => {
            try {
                const response = await axios.get('http://localhost:5000/api/sports');
                setSports(response.data);
                console.log("Sports récupérés :", response.data);
            } catch (err) {
                setError('Erreur lors de la récupération des sports');
                console.error("Erreur de récupération des sports :", err);
            }
        };

        fetchEvents();
        fetchSports();
    }, []);

// Fonction pour récupérer les événements en litige
const fetchDisputedEvents = async () => {
  try {
    // Récupérer les TempScores avec le statut DISPUTED
    const tempScoresResponse = await axios.get('http://localhost:5000/api/temp-scores', {
      params: { status: 'DISPUTED' }
    });

    console.log('TempScores récupérés:', tempScoresResponse.data);

    if (!tempScoresResponse.data || tempScoresResponse.data.length === 0) {
      return null;
    }

    // Récupérer l'événement correspondant au premier TempScore
    const firstTempScore = tempScoresResponse.data[0];
    const eventId = typeof firstTempScore.eventId === 'object' ? 
      firstTempScore.eventId._id : firstTempScore.eventId;

    const eventResponse = await axios.get(`http://localhost:5000/api/events/${eventId}`);

    // Filtrer les TempScores pour cet événement
    const eventTempScores = tempScoresResponse.data.filter(
      score => (score.eventId._id || score.eventId) === eventId
    );

    // Combiner l'événement avec ses TempScores
    const disputedEvent = {
      ...eventResponse.data,
      tempScores: eventTempScores
    };

    console.log('Événement en litige avec scores:', disputedEvent);
    return disputedEvent;

  } catch (error) {
    console.error('Erreur lors de la récupération des litiges:', error);
    return null;
  }
};


// Mise à jour du gestionnaire de résolution des litiges
const handleDisputeResolution = async (eventId) => {
    try {
        const response = await axios.post(`http://localhost:5000/api/events/${eventId}/resolve-dispute`, {
            scores: {
                team1: parseInt(resolvedScores.team1),
                team2: parseInt(resolvedScores.team2)
            }
        });

        if (response.data.status === 'success') {
            setEvents(prevEvents => 
                prevEvents.map(event => 
                    event._id === eventId ? {
                        ...event,
                        status: 'Terminé',
                        teams: [
                            { ...event.teams[0], score: parseInt(resolvedScores.team1) },
                            { ...event.teams[1], score: parseInt(resolvedScores.team2) }
                        ],
                        tempScores: event.tempScores.map(score => ({
                            ...score,
                            status: 'RESOLVED'
                        }))
                    } : event
                )
            );
            setShowDisputeModal(false);
            setDisputedEvent(null);
            setResolvedScores({ team1: 0, team2: 0 });
            alert('Le litige a été résolu avec succès');
        }
    } catch (error) {
        console.error('Erreur lors de la résolution du litige:', error);
        alert('Erreur lors de la résolution du litige');
    }
};

const openDetailsModal = async (event) => {
    try {
        const eventResponse = await axios.get(`http://localhost:5000/api/events/${event._id}`);
        const updatedEvent = eventResponse.data;
        
        let subscribers = [];
        let subscribersCount = 0;

        try {
            const subscribersResponse = await axios.get(
                `http://localhost:5000/api/event-subscriptions/${event._id}`
            );
            subscribers = subscribersResponse.data;

            const countResponse = await axios.get(
                `http://localhost:5000/api/event-subscriptions/count/${event._id}`
            );
            subscribersCount = countResponse.data.count;
        } catch (subError) {
            console.log('Pas encore d\'inscriptions pour cet événement');
        }

        const isTeamConfigured = updatedEvent.teamConfiguration?.isConfigured;
        setIsCaptainSelectionMode(!isTeamConfigured && updatedEvent.status === 'En cours');

        // Initialiser les capitaines à partir des équipes existantes
        if (updatedEvent.teams && updatedEvent.teams.length === 2) {
            const team1Captain = updatedEvent.teams[0]?.players?.find(p => p.isCapitain);
            const team2Captain = updatedEvent.teams[1]?.players?.find(p => p.isCapitain);
            
            if (team1Captain || team2Captain) {
                const captain1Info = subscribers.find(sub => 
                    sub.user._id === (typeof team1Captain?.user === 'string' ? team1Captain.user : team1Captain?.user?._id)
                );
                const captain2Info = subscribers.find(sub => 
                    sub.user._id === (typeof team2Captain?.user === 'string' ? team2Captain.user : team2Captain?.user?._id)
                );

                const newSelectedCaptains = {
                    team1: captain1Info ? {
                        _id: typeof team1Captain?.user === 'string' ? team1Captain.user : team1Captain?.user?._id,
                        user: captain1Info.user
                    } : null,
                    team2: captain2Info ? {
                        _id: typeof team2Captain?.user === 'string' ? team2Captain.user : team2Captain?.user?._id,
                        user: captain2Info.user
                    } : null
                };

                setSelectedCaptains(newSelectedCaptains);

                // Mettre à jour l'état de verrouillage des capitaines
                const areCaptainsSelected = team1Captain && team2Captain;
                setCaptainsLocked(areCaptainsSelected || isTeamConfigured);
            }
        }

        if (!updatedEvent.teams) {
            updatedEvent.teams = [];
        }

        setSelectedEvent(updatedEvent);
        setSelectedEventSubscribers(subscribers);
        setShowDetailsModal(true);
    } catch (error) {
        console.error('Erreur lors de l\'ouverture du modal:', error);
        setError('Erreur lors de l\'ouverture du modal');
    }
};

const handleCreateEvent = async (e) => {
    e.preventDefault();
    try {
        // Ajout des champs manquants dans newEvent
        const eventToCreate = {
            ...newEvent,
            teamConfiguration: {
                isConfigured: false
            },
            teams: [], // Initialisation du tableau des équipes
            subscribersCount: 0
        };

        const response = await axios.post('http://localhost:5000/api/events', eventToCreate);
        
        // Trouver le sport correspondant pour ajouter son nom
        const selectedSport = sports.find(sport => sport._id === newEvent.sport);
        
        // Créer un nouvel événement avec toutes les données nécessaires
        const newEventWithSport = {
            ...response.data,
            sport: {
                ...response.data.sport,
                name: selectedSport.name
            },
            sportName: selectedSport.name,
            teamConfiguration: {
                isConfigured: false
            },
            teams: [],
            subscribersCount: 0
        };

        // Mettre à jour la liste des événements
        setEvents([...events, newEventWithSport]);
        
        // Réinitialiser le formulaire
        setShowCreateForm(false);
        setNewEvent({
            title: '',
            date: '',
            location: '',
            status: '',
            sport: '',
        });
        
    } catch (err) {
        setError("Erreur lors de la création de l'événement");
        console.error("Erreur lors de la création :", err.response ? err.response.data : err.message);
    }
};

const handleDeleteEvent = async (eventId) => {
        const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cet événement ?");
        if (confirmed) {
            try {
                await axios.delete(`http://localhost:5000/api/events/${eventId}`);
                setEvents(events.filter(event => event._id !== eventId));
            } catch (err) {
                setError("Erreur lors de la suppression de l'événement");
            }
        }
    };

    // Dans EventManagement.js
const handleEditEvent = async (e) => {
    e.preventDefault();
    try {
        const selectedSport = sports.find(sport => sport._id === editEvent.sport._id);
        console.log("Sport sélectionné:", selectedSport);
        console.log("Event à modifier:", editEvent);
        
        // Vérification des champs obligatoires
        if (!editEvent.title || !editEvent.date || !editEvent.sport || !editEvent.location) {
            console.log("Champs manquants:", {
                title: editEvent.title,
                date: editEvent.date,
                sport: editEvent.sport,
                location: editEvent.location
            });
            setError("Tous les champs obligatoires doivent être remplis");
            return;
        }

        // Construction de l'objet à mettre à jour
        const eventToUpdate = {
            title: editEvent.title,
            date: editEvent.date,
            sport: selectedSport._id, // Utiliser l'ID du sport sélectionné
            location: editEvent.location,
            status: editEvent.status || 'À venir',
            description: editEvent.description || '',
            maxParticipants: editEvent.maxParticipants || 0,
            teamConfiguration: {
                isConfigured: editEvent.teamConfiguration?.isConfigured || false,
                maxTeams: editEvent.teamConfiguration?.maxTeams || 2,
                playersPerTeam: editEvent.teamConfiguration?.playersPerTeam || 5
            },
            teams: editEvent.teams?.map(team => {
                console.log("Traitement de l'équipe:", team);
                return {
                    _id: team._id,
                    event: editEvent._id,
                    score: team.score || 0,
                    teamElo: team.teamElo || 1000,
                    players: team.players?.map(player => {
                        console.log("Traitement du joueur:", player);
                        return {
                            user: player.user._id || player.user,
                            isCapitain: player.isCapitain || false,
                            elo: player.elo || 1000,
                            present: player.present || true
                        };
                    }) || [],
                    status: team.status || 'confirmed'
                };
            }) || [],
            eloStats: editEvent.eloStats || {
                team1: {
                    initialElo: editEvent.teams?.[0]?.teamElo || 1000,
                    finalElo: editEvent.teams?.[0]?.teamElo || 1000,
                    deltaElo: 0
                },
                team2: {
                    initialElo: editEvent.teams?.[1]?.teamElo || 1000,
                    finalElo: editEvent.teams?.[1]?.teamElo || 1000,
                    deltaElo: 0
                },
                eloDifference: Math.abs(
                    (editEvent.teams?.[0]?.teamElo || 1000) - 
                    (editEvent.teams?.[1]?.teamElo || 1000)
                )
            }
        };

        console.log("Objet à mettre à jour:", eventToUpdate);

        const response = await axios.put(
            `http://localhost:5000/api/events/${editEvent._id}`, 
            eventToUpdate
        );

        console.log("Réponse du serveur:", response.data);

        const updatedEvent = {
            ...response.data,
            sport: selectedSport,
            sportName: selectedSport.name
        };

        console.log("Event mis à jour:", updatedEvent);

        setEvents(events.map(event => 
            event._id === editEvent._id ? updatedEvent : event
        ));

        setShowEditForm(false);
        setEditEvent(null);

    } catch (err) {
        console.error("Erreur complète:", err);
        console.error("Détails de l'erreur:", {
            message: err.message,
            response: err.response?.data,
            status: err.response?.status
        });
        setError("Erreur lors de la mise à jour de l'événement");
    }
};

    const formatDateForInput = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Renvoie la date au format YYYY-MM-DD
    };

    const filteredEvents = events.filter(event => {
        return (
            (filter.title === '' || event.title === filter.title) &&
            (filter.status === '' || event.status === filter.status) &&
            (filter.sport === '' || event.sport.name === filter.sport)
        );
    });

    const openEditForm = (event) => {
        console.log("Événement sélectionné pour modification :", event);
        setEditEvent(event);
        setShowEditForm(true);
    };

    // Ajout de la fonction de sélection des capitaines
const handleCaptainSelection = async (userId, teamIndex) => {
    try {
        // Vérifier si l'événement a des équipes
        if (!selectedEvent.teams || selectedEvent.teams.length === 0) {
            const teamsResponse = await axios.post(`http://localhost:5000/api/events/${selectedEvent._id}/teams`, {
                teams: [
                    {
                        players: teamIndex === 0 ? [{ 
                            user: userId, 
                            isCapitain: true 
                        }] : [],
                        status: 'confirmed'
                    },
                    {
                        players: teamIndex === 1 ? [{ 
                            user: userId, 
                            isCapitain: true 
                        }] : [],
                        status: 'confirmed'
                    }
                ]
            });

            setSelectedEvent(prev => ({
                ...prev,
                teams: teamsResponse.data.teams
            }));
        } else {
            // Si les équipes existent déjà, mettre à jour le capitaine
            const updatedTeams = selectedEvent.teams.map((team, idx) => {
                if (idx === teamIndex) {
                    return {
                        ...team,
                        players: [...team.players, { 
                            user: userId, 
                            isCapitain: true 
                        }],
                        status: 'confirmed'
                    };
                }
                return team;
            });

            const response = await axios.put(`http://localhost:5000/api/events/${selectedEvent._id}/teams`, {
                teams: updatedTeams
            });

            setSelectedEvent(prev => ({
                ...prev,
                teams: response.data.teams
            }));
        }

        // Trouver l'utilisateur sélectionné dans les subscribers
        const selectedUser = selectedEventSubscribers.find(sub => sub.user._id === userId);

        // Mettre à jour les capitaines sélectionnés
        setSelectedCaptains(prev => ({
            ...prev,
            [`team${teamIndex + 1}`]: {
                _id: userId,
                user: selectedUser.user
            }
        }));

        // Envoyer la notification au capitaine
        await axios.post(`http://localhost:5000/api/notifications/captain`, {
            userId: userId,
            eventId: selectedEvent._id,
            teamIndex: teamIndex
        });       

    } catch (error) {
        console.error('Erreur lors de la désignation du capitaine:', error);
        setError("Erreur lors de la création des équipes ou de la désignation du capitaine");
    }
};

const handleResolveDispute = (event) => {
    setSelectedEvent(event);
    setShowDisputeModal(true);
};

const handleStatusUpdate = async (newStatus) => {
  try {
    const response = await axios.put(
      `http://localhost:5000/api/events/${selectedEvent._id}/status`,
      { status: newStatus }
    );

    setSelectedEvent(response.data);
    
  } catch (error) {
    console.error('Erreur lors de la mise à jour du statut:', error);
    setError("Erreur lors de la mise à jour du statut de l'événement");
  }
};

    return (
        <main className="main-content">
            <div className="header">
                <h1>Gestion des Événements</h1>
                <button 
                    className="btn btn-primary2"
                    onClick={() => setShowCreateForm(true)}
                >
                    <i className="mdi mdi-plus"></i>
                    Créer un événement
                </button>
            </div>

            <Modal isOpen={showCreateForm} onClose={() => setShowCreateForm(false)}>
                <div className="modal-header">
                    <h2>Créer un nouvel événement</h2>
                </div>
                <div className="form-group">
                    <form onSubmit={handleCreateEvent}>
                        <select
                            value={newEvent.title}
                            onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                            required
                        >
                            <option value="">Type d'événement</option>
                            <option value="Tournoi">Tournoi</option>
                            <option value="Match">Match</option>
                            <option value="Entraînement">Entraînement</option>
                        </select>
                        <input
                            type="date"
                            placeholder="Date"
                            value={newEvent.date}
                            onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Lieu"
                            value={newEvent.location}
                            onChange={(e) => setNewEvent({ ...newEvent, location: e.target.value })}
                            required
                        />
                        <select
                            value={newEvent.status}
                            onChange={(e) => setNewEvent({ ...newEvent, status: e.target.value })}
                            required
                        >
                            <option value="">Statut</option>
                            <option value="À venir">À venir</option>
                            <option value="En cours">En cours</option>
                            <option value="Terminé">Terminé</option>
                        </select>
                        <select
                            value={newEvent.sport}
                            onChange={(e) => setNewEvent({ ...newEvent, sport: e.target.value })}
                            required
                        >
                            <option value="">Sport</option>
                            {sports.map(sport => (
                                <option key={sport._id} value={sport._id}>{sport.name}</option>
                            ))}
                        </select>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Ajouter l'événement</button>
                        </div>
                    </form>
                </div>
            </Modal>

                 {/* Modal pour éditer un événement */}
            <Modal isOpen={showEditForm} onClose={() => setShowEditForm(false)}>
                <div className="modal-header">
                    <h2>Modifier l'événement</h2>
                </div>
                {editEvent && (
                    <div className="form-group">
                        <form onSubmit={handleEditEvent}>
                            <select
                                value={editEvent.title}
                                onChange={(e) => setEditEvent({ ...editEvent, title: e.target.value })}
                                required
                            >
                                <option value="">Type d'événement</option>
                                <option value="Tournoi">Tournoi</option>
                                <option value="Match">Match</option>
                                <option value="Entraînement">Entraînement</option>
                            </select>
                            <input
                                type="date"
                                placeholder="Date"
                                value={formatDateForInput(editEvent.date)}
                                onChange={(e) => setEditEvent({ ...editEvent, date: e.target.value })}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Lieu"
                                value={editEvent.location}
                                onChange={(e) => setEditEvent({ ...editEvent, location: e.target.value })}
                                required
                            />
                            <select
                                value={editEvent.status}
                                onChange={(e) => setEditEvent({ ...editEvent, status: e.target.value })}
                                required
                            >
                                <option value="">Statut</option>
                                <option value="À venir">À venir</option>
                                <option value="En cours">En cours</option>
                                <option value="Terminé">Terminé</option>
                            </select>
                            <select
                                value={editEvent.sport._id}
                                onChange={(e) => setEditEvent({ ...editEvent, sport: e.target.value })}
                                required
                            >
                                <option value="">Sport</option>
                                {sports.map(sport => (
                                    <option key={sport._id} value={sport._id}>{sport.name}</option>
                                ))}
                            </select>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Mettre à jour l'événement</button>
                            </div>
                        </form>
                    </div>
                )}
            </Modal>

           
            <Modal 
            isOpen={showDetailsModal} 
            onClose={async () => {
                try {
                    // Vérifier si l'événement est en cours, si les équipes ne sont pas configurées
                    // et s'il y a des inscrits
                    if (selectedEvent?.status === 'En cours' && 
                        !selectedEvent.teamConfiguration?.isConfigured && 
                        selectedEventSubscribers.length > 0 && 
                        (!selectedCaptains.team1 || !selectedCaptains.team2)) {
                        alert('Veuillez sélectionner les capitaines des deux équipes avant de fermer');
                        return;
                    }

                    // Sauvegarder les capitaines uniquement si l'événement est en cours,
                    // qu'il y a des inscrits et que les équipes ne sont pas encore configurées
                    if (selectedEvent?.status === 'En cours' && 
                        !selectedEvent.teamConfiguration?.isConfigured && 
                        selectedEventSubscribers.length > 0 &&
                        selectedCaptains.team1 && 
                        selectedCaptains.team2) {
                        
                        await axios.post(`http://localhost:5000/api/events/${selectedEvent._id}/teams`, {
                            teams: [
                                {
                                    players: [{ 
                                        user: selectedCaptains.team1.user._id, 
                                        isCapitain: true 
                                    }]
                                },
                                {
                                    players: [{ 
                                        user: selectedCaptains.team2.user._id, 
                                        isCapitain: true 
                                    }]
                                }
                            ]
                        });
                    }

                    // Fermer le modal
                    setShowDetailsModal(false);
                    
                    // Réinitialiser les états uniquement si nécessaire
                    if (!selectedEvent.teamConfiguration?.isConfigured) {
                        setSelectedCaptains({ team1: null, team2: null });
                        setCaptainsLocked(false);
                    }
                } catch (error) {
                    console.error('Erreur lors de la sauvegarde des capitaines:', error);
                    alert('Erreur lors de la sauvegarde des modifications');
                }
            }}
           
        >
                {selectedEvent && (
                    
                    <div className="event-details-modal">
                        <h2>{selectedEvent.title}</h2>
                        <div className="event-info">
                            <p><strong>Sport:</strong> {selectedEvent.sport.name}</p>
                            <p><strong>Date:</strong> {formatDateForInput(selectedEvent.date)}</p>
                            <p><strong>Lieu:</strong> {selectedEvent.location}</p>
                            <p><strong>Statut:</strong> {selectedEvent.status}</p>
                            <p><strong>Nombre de participants:</strong> {selectedEvent.subscribersCount || 0} </p>
                        </div>

                       {selectedEvent.status === 'En cours' && (
                            <div className="captain-selection">
                                <h4>Désignation des capitaines</h4>
                                <div className="teams-container">
                                    {['Noire', 'Bleue'].map((teamColor, index) => (
                                        <div key={index} className="team-captain-selection">
                                            <h5>Équipe {teamColor}</h5>
                                            <select 
                                                onChange={(e) => handleCaptainSelection(e.target.value, index)}
                                                value={selectedCaptains[`team${index + 1}`]?.user?._id || ""}
                                                className={`${!selectedCaptains[`team${index + 1}`] ? 'invalid-select' : ''} 
                                                        ${captainsLocked ? 'locked' : ''}`}
                                                disabled={captainsLocked}
                                            >
                                                <option value="">
                                                    {captainsLocked && selectedCaptains[`team${index + 1}`]
                                                        ? `Capitaine: ${selectedCaptains[`team${index + 1}`].user.nom} (Verrouillé)`
                                                        : "Sélectionner un capitaine *"
                                                    }
                                                </option>
                                                {selectedEventSubscribers && 
                                                    [...new Map(selectedEventSubscribers.map(sub => 
                                                        [sub.user._id, sub])).values()]
                                                    .map((subscriber) => {
                                                        const isCurrentCaptain = selectedCaptains[`team${index + 1}`]?.user?._id === subscriber.user._id;
                                                        const isOtherTeamCaptain = selectedCaptains[`team${index === 0 ? 2 : 1}`]?.user?._id === subscriber.user._id;
                                                        
                                                        // Ne pas afficher les joueurs déjà capitaines d'une autre équipe
                                                        if (isOtherTeamCaptain) return null;

                                                        return (
                                                            <option 
                                                                key={`${subscriber.user._id}-${index}`}
                                                                value={subscriber.user._id}
                                                                disabled={captainsLocked && !isCurrentCaptain}
                                                            >
                                                                {subscriber.user.nom}
                                                                {isCurrentCaptain && captainsLocked ? ' (Capitaine verrouillé)' : ''}
                                                            </option>
                                                        );
                                                    }).filter(Boolean)
                                                }
                                            </select>
                                            {!selectedCaptains[`team${index + 1}`] && !captainsLocked && (
                                                <span className="error-message">Sélection du capitaine obligatoire</span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className="subscribers-list">
                            <h4>Liste des inscrits</h4>
                            {selectedEventSubscribers && selectedEventSubscribers.length > 0 ? (
                                <div className="subscribers-columns">
                                    {/* Première colonne */}
                                    <ul>
                                        {selectedEventSubscribers
                                            .slice(0, selectedEventSubscribers.length <= 10 ? 5 : 10)
                                            .map((subscriber, index) => (
                                                <li key={index} className="subscriber-item">
                                                    <span className="player-number">{index + 1}</span>
                                                    <span>{subscriber.user.nom}</span>
                                                    <span className="joined-date">
                                                        {new Date(subscriber.joinedAt).toLocaleDateString('fr-FR', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric',
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        })}
                                                    </span>
                                                </li>
                                            ))}
                                    </ul>

                                    {/* Deuxième colonne */}
                                    <ul>
                                        {selectedEventSubscribers
                                            .slice(
                                                selectedEventSubscribers.length <= 10 ? 5 : 10,
                                                selectedEventSubscribers.length <= 20 ? selectedEventSubscribers.length : 20
                                            )
                                            .map((subscriber, index) => (
                                                <li 
                                                    key={index + (selectedEventSubscribers.length <= 10 ? 5 : 10)} 
                                                    className="subscriber-item"
                                                >
                                                    <span className="player-number">
                                                        {index + 1 + (selectedEventSubscribers.length <= 10 ? 5 : 10)}
                                                    </span>
                                                    <span>{subscriber.user.nom}</span>
                                                    <span className="joined-date">
                                                        {new Date(subscriber.joinedAt).toLocaleDateString('fr-FR', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric',
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        })}
                                                    </span>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            ) : (
                                <p>Aucun inscrit pour le moment</p>
                            )}
                        </div>
                        {(selectedEvent.status === 'Terminé' || selectedEvent.teamConfiguration?.isConfigured) && selectedEvent.teams && (
                            <div className={`${selectedEvent.status === 'Terminé' ? 'teams-details' : 'teams-composition'}`}>
                                {console.log("Données envoyées à TeamCompositionWithScore:", {
                                    teams: selectedEvent.teams,
                                    isConfigured: selectedEvent.teamConfiguration?.isConfigured,
                                    status: selectedEvent.status
                                })}
                                <TeamCompositionWithScore 
                                    teams={[
                                        {
                                            ...selectedEvent.teams[0],
                                            players: selectedEvent.teams[0].players.map(player => ({
                                                ...player,
                                                user: selectedEventSubscribers.find(sub => 
                                                    sub.user._id === (typeof player.user === 'string' ? player.user : player.user._id)
                                                )?.user || player.user
                                            }))
                                        },
                                        {
                                            ...selectedEvent.teams[1],
                                            players: selectedEvent.teams[1].players.map(player => ({
                                                ...player,
                                                user: selectedEventSubscribers.find(sub => 
                                                    sub.user._id === (typeof player.user === 'string' ? player.user : player.user._id)
                                                )?.user || player.user
                                            }))
                                        }
                                    ]} 
                                />
                            </div>
                        )}

                    </div>
                )}
            </Modal>

            {/*<DisputeResolutionModal 
            isOpen={showDisputeModal}
            onClose={() => setShowDisputeModal(false)}
            disputedEvent={disputedEvent}
            resolvedScores={resolvedScores}
            setResolvedScores={setResolvedScores}
            handleDisputeResolution={handleDisputeResolution}
        />*/}

            <DisputeResolutionModal 
                isOpen={showDisputeModal}
                onClose={() => {
                    setShowDisputeModal(false);
                    setDisputedEvent(null);
                    setResolvedScores({ team1: 0, team2: 0 });
                }}
                disputedEvent={disputedEvent}
                resolvedScores={resolvedScores}
                setResolvedScores={setResolvedScores}
                handleDisputeResolution={handleDisputeResolution}
            />

            


       
            <div className="filters">
                <select
                    className="filter-input"
                    value={filter.title}
                    onChange={(e) => setFilter({ ...filter, title: e.target.value })}
                >
                    <option value="">Type d'événement</option>
                    <option value="Tournoi">Tournoi</option>
                    <option value="Match">Match</option>
                    <option value="Entraînement">Entraînement</option>
                </select>
                <select
                    className="filter-input"
                    value={filter.status}
                    onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                >
                    <option value="">Statut</option>
                    <option value="À venir">À venir</option>
                    <option value="En cours">En cours</option>
                    <option value="Terminé">Terminé</option>
                </select>
                <select
                    className="filter-input"
                    value={filter.sport}
                    onChange={(e) => setFilter({ ...filter, sport: e.target.value })}
                >
                    <option value="">Sport</option>
                    {sports.map(sport => (
                        <option key={sport._id} value={sport.name}>{sport.name}</option>
                    ))}
                </select>

                <button 
                    className="btn btn-warning"
                    onClick={async () => {
                        try {
                            const disputedEvent = await fetchDisputedEvents();
                            if (disputedEvent) {
                                setDisputedEvent(disputedEvent);
                                setShowDisputeModal(true);
                            } else {
                                alert('Aucun litige en cours');
                            }
                        } catch (error) {
                            console.error('Erreur:', error);
                            alert('Erreur lors de la récupération des litiges');
                        }
                    }}
                >
                    Voir les litiges
                </button>

                
            </div>

            <div className="events-list">
                {loading ? (
                    <p>Chargement...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    filteredEvents.map(event => (
                        <EventCard 
                            key={event._id} 
                            event={event} 
                            sportName={event.sportName} 
                            onDetails={openDetailsModal}
                            onDelete={handleDeleteEvent} 
                            onEdit={openEditForm}
                            onResolveDispute={(event) => {
                                setDisputedEvent(event);
                                setShowDisputeModal(true);
                            }}
                        />
                    ))
                )}
            </div>
        </main>
    );
};

export default EventManagement;
