// src/components/Modal.js
import React from 'react';
import './Modal.css'; // Assurez-vous de créer un fichier CSS pour le style

// Dans le composant Modal, modifiez la gestion de la fermeture
const Modal = ({ isOpen, onClose, children }) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button 
                    className="modal-close" 
                    onClick={handleClose}  // Utilise la même fonction que onClose
                >
                    <i className="mdi mdi-close"></i>
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
