import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import './Auth.css';

const Auth = () => {
  const { login, signup, isAuthenticated, user } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    nom: '',
    prenom: ''
  });
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value.trim()
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    
    try {
      if (isLoginMode) {
        const response = await login(formData.email, formData.password);
        if (response?.utilisateur?._id) {
          localStorage.setItem('userId', response.utilisateur._id);
        }
      } else {
        if (formData.password.length < 6) {
          throw new Error('Le mot de passe doit contenir au moins 6 caractères');
        }
        await signup(
          formData.email, 
          formData.password, 
          formData.nom, 
          formData.prenom
        );
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || error.message || 'Une erreur est survenue');
    }
  };

  useEffect(() => {
    if (isAuthenticated && user?.role) {
      navigate(user.role === 'admin' ? '/admin-dashboard' : '/user-dashboard');
    }
  }, [isAuthenticated, navigate, user]);

  return (
    <div className="auth-container">
      <div className="auth-form-wrapper">
        <h2>{isLoginMode ? 'Connexion' : 'Inscription'}</h2>
        
        <form onSubmit={handleSubmit} className="auth-form">
          {!isLoginMode && (
            <div className="form-group">
              <input
                type="text"
                name="nom"
                placeholder="Nom"
                value={formData.nom}
                onChange={handleChange}
                required
                className="auth-input"
              />
              <input
                type="text"
                name="prenom"
                placeholder="Prénom"
                value={formData.prenom}
                onChange={handleChange}
                required
                className="auth-input"
              />
            </div>
          )}
          
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
              className="auth-input"
            />
            
            <input
              type="password"
              name="password"
              placeholder="Mot de passe"
              value={formData.password}
              onChange={handleChange}
              required
              className="auth-input"
              minLength="6"
            />
          </div>

          <button type="submit" className="submit-btn">
            {isLoginMode ? 'Se connecter' : 'S\'inscrire'}
          </button>
        </form>

        {errorMessage && (
          <p className="error-message">
            <i className="fas fa-exclamation-circle"></i> {errorMessage}
          </p>
        )}

        <button 
          className="switch-mode-btn"
          onClick={() => {
            setIsLoginMode(prev => !prev);
            setErrorMessage('');
            setFormData({
              email: '',
              password: '',
              nom: '',
              prenom: ''
            });
          }}
        >
          {isLoginMode 
            ? 'Pas encore inscrit ? S\'inscrire' 
            : 'Déjà inscrit ? Se connecter'}
        </button>

        <Link to="/" className="return-home-link">
          Retour à l'accueil
        </Link>


        {isAuthenticated && user && (
          <div className="welcome-message">
            <i className="fas fa-check-circle"></i>
            <h3>Bienvenue, {user.prenom} {user.nom}!</h3>
            <p>Vous êtes maintenant connecté.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Auth;