import React, { createContext, useState, useContext } from 'react'; // Importation de useContext ici
import axios from 'axios'; // Importation d'Axios

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // État pour gérer les messages d'erreur

  const login = async (email, password) => {
    try {
      const credentials = { email, motDePasse: password }; // Vérifie que le nom de la propriété correspond à l'API
      const response = await axios.post('http://localhost:5000/api/auth/login', credentials, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setIsAuthenticated(true);
      setUser({
        ...response.data.utilisateur,
        id: response.data.utilisateur._id, // Vérifiez que l'ID est correct ici
        prenom: response.data.utilisateur.prenom, // Assurez-vous que ces champs existent
        nom: response.data.utilisateur.nom,
        role: response.data.utilisateur.role, // Assurez-vous que le rôle est présent dans la réponse
      });
      console.log('Connexion réussie:', response.data);
      setErrorMessage(''); // Réinitialiser le message d'erreur en cas de succès
    } catch (error) {
      console.error('Erreur lors de la connexion:', error.response ? error.response.data.message : error.message);
      setErrorMessage(error.response ? error.response.data.message : 'Erreur de connexion'); // Mettre à jour le message d'erreur
      throw error; // Propager l'erreur pour la gestion en amont
    }
  };

  const signup = async (nom, prenom, email, password, role, statut) => {
    console.log('Données d\'inscription envoyées:', { nom, prenom, email, password, role, statut });

    try {
      const userData = { nom, prenom, email, motDePasse: password, role, statut }; // Assure-toi d'inclure le rôle et le statut
      console.log('Envoi de la requête d\'inscription à l\'API...');

      const response = await axios.post('http://localhost:5000/api/auth/signup', userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Inscription réussie:', response.data.message);
      await login(email, password); // Connexion automatique après l'inscription
      setErrorMessage(''); // Réinitialiser le message d'erreur en cas de succès
    } catch (error) {
      console.error('Erreur lors de l\'inscription:', error.response ? error.response.data.message : error.message);
      setErrorMessage(error.response ? error.response.data.message : 'Erreur d\'inscription'); // Mettre à jour le message d'erreur
    }
  };

  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, signup, logout, errorMessage }}>
      {children}
    </AuthContext.Provider>
  );
};

// Ajout de useContext ici pour éviter des importations redondantes
export const useAuth = () => useContext(AuthContext);

export { AuthProvider };
