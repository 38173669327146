// src/components/AdminDashboard.js
import React, { useState } from 'react';
import AdminSidebar from './AdminSidebar';
import Dashboard from './Dashboard';
import EventManagement from './EventManagement';
import SubscriptionManager from './SubscriptionManager';
import FormulasManager from './FormulasManager';
import UsersManagement from './UsersManagement';

import './AdminDashboard.css';

const AdminDashboard = () => {
    const [activeView, setActiveView] = useState('dashboard');

    const handleShowDashboard = () => setActiveView('dashboard');
    const handleShowSubscriptions = () => setActiveView('subscriptions');
    const handleShowFormulas = () => setActiveView('formulas');
    const handleShowEvents = () => setActiveView('events');
    const handleShowUsers = () => setActiveView('users');

    return (
        <div className="admin-dashboard">
            <AdminSidebar
                onShowDashboard={handleShowDashboard}
                onShowSubscriptions={handleShowSubscriptions}
                onShowFormulas={handleShowFormulas}
                onShowEvents={handleShowEvents}
                onShowUsers={handleShowUsers}
            />
            <main className="main-content2">
                {activeView === 'dashboard' &&  <Dashboard />}
                {activeView === 'subscriptions' && <SubscriptionManager/>}
                {activeView === 'formulas' && <FormulasManager/>}
                {activeView === 'events' && <EventManagement />}
                {activeView === 'users' && <UsersManagement/>}
            </main>
        </div>
    );
};

export default AdminDashboard;
