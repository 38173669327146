import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UsersManagement.css';

const AlertModal = ({ message, onClose }) => (
    <div className="modal-overlay">
        <div className="modal">
            <div className="modal-Alert-header">
                <h2 className="alert-title">Alerte</h2> 
                <button className="modal-Alert-close" onClick={onClose}>&times;</button>
            </div>
            <div className="modal-Alert-body">
                <p className="alert-message">{message}</p>
            </div>
            <div className="modal-Alert-footer">
                <button className="btn btn-primary" onClick={onClose}>Fermer</button>
            </div>
        </div>
    </div>
);

const UsersManagement = () => {
    const [users, setUsers] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isAlertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [newUser, setNewUser] = useState({
        nom: '',
        pseudo: '',
        email: '',
        role: '',
        status: 'En attente',
        motDePasse: 'tempPassword123',
    });
    const [editingUser, setEditingUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState({ nom: '', status: '', role: '' });

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10);

    const statusValues = {
        PENDING: 'En attente',
        ACTIVE: 'Valide',
        EXPIRED: 'Expiré',
        UNKNOWN: 'Inconnu',
    };

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('http://localhost:5000/api/users/all');
                setUsers(response.data);
                setLoading(false);
            } catch (err) {
                setError('Erreur lors du chargement des utilisateurs');
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    // Gestion des changements dans le sélecteur de taille de page
    const handlePageSizeChange = (e) => {
        setUsersPerPage(Number(e.target.value)); // Mettez à jour le nombre d'utilisateurs par page
        setCurrentPage(1); // Réinitialisez à la première page lors du changement de taille
    };

    // Gestion du changement de valeur des inputs du formulaire
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };

    // Ajout de la gestion des changements pour le modal d'édition
    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditingUser({ ...editingUser, [name]: value });
    };

    // Fonction pour ajouter un nouvel utilisateur
    const handleAddUser = async () => {
        try {
            const response = await axios.post('http://localhost:5000/api/users/add', {
                ...newUser,
                status: 'Valide',
            });
            const addedUser = response.data.user;

            setUsers([...users, { ...addedUser, status: 'Valide', avatar: 'https://randomuser.me/api/portraits/men/1.jpg' }]);
            setModalOpen(false);
            setNewUser({ nom: '', email: '', pseudo: '', role: '', motDePasse: '' });
            setAlertMessage('Utilisateur ajouté avec succès !'); // Message de succès
            setAlertOpen(true); // Ouvrir le modal d'alerte
        } catch (error) {
            console.error('Erreur lors de l’ajout de l’utilisateur:', error);
            if (error.response && error.response.status === 400) {
                setAlertMessage('Cet email est déjà utilisé. Veuillez en choisir un autre.');
            } else {
                setAlertMessage('Erreur lors de l’ajout de l’utilisateur.');
            }
            setAlertOpen(true); // Ouvrir le modal d'alerte
        }
    };

    // Fonction pour ouvrir le modal d'édition et remplir les champs
    const handleEditUser = (user) => {
        setEditingUser(user);
        setEditModalOpen(true);
    };

    // Fonction pour enregistrer les modifications de l'utilisateur
    const handleSaveChanges = async () => {
        if (!editingUser || !editingUser._id) {
            console.error("L'utilisateur à modifier est introuvable.");
            setAlertMessage("Erreur : l'utilisateur n'a pas été trouvé.");
            setAlertOpen(true);
            return;
        }
    
        try {
            const response = await axios.put(`http://localhost:5000/api/users/edit/${editingUser._id}`, editingUser);
            
            // Log de la réponse pour débogage
            console.log("Réponse de l'API :", response.data);
    
            // Assurez-vous que la réponse contient l'utilisateur modifié
            if (response.data) {
                const updatedUser = response.data; // Utilisez response.data directement
                setUsers(users.map(user => (user._id === updatedUser._id ? updatedUser : user)));
                setEditModalOpen(false);
                setEditingUser(null);
                setAlertMessage('Utilisateur modifié avec succès !');
                setAlertOpen(true);
            } else {
                console.error("Aucune donnée utilisateur trouvée dans la réponse.");
                setAlertMessage('Erreur : utilisateur non modifié.');
                setAlertOpen(true);
            }
        } catch (error) {
            console.error('Erreur lors de la modification de l’utilisateur:', error);
            setAlertMessage('Erreur lors de la modification de l’utilisateur.');
            setAlertOpen(true);
        }
    };

    // Fonction pour supprimer un utilisateur
    const handleDeleteUser = async (userId) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) {
            try {
                await axios.delete(`http://localhost:5000/api/users/delete/${userId}`);
                setUsers(users.filter(user => user._id !== userId));
            } catch (error) {
                console.error('Erreur lors de la suppression de l’utilisateur:', error);
                setAlertMessage('Erreur lors de la suppression de l’utilisateur');
                setAlertOpen(true); // Ouvrir le modal d'alerte
            }
        }
    };

    // Fonction pour réinitialiser le mot de passe d'un utilisateur
    const handleResetPassword = async (userId) => {
        if (window.confirm('Êtes-vous sûr de vouloir réinitialiser le mot de passe de cet utilisateur ?')) {
            try {
                const response = await axios.put(`http://localhost:5000/api/users/reset-password/${userId}`);
                setAlertMessage(response.data.message || 'Mot de passe réinitialisé avec succès !');
                setAlertOpen(true);
            } catch (error) {
                console.error('Erreur lors de la réinitialisation du mot de passe:', error);
                setAlertMessage('Erreur lors de la réinitialisation du mot de passe.');
                setAlertOpen(true);
            }
        }
    };



    const handleModalToggle = () => {
        setModalOpen(!isModalOpen);
    };

    // Ajoutez cette fonction pour basculer la visibilité du mot de passe
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

     // Filtered users
     const filteredUsers = users.filter(user => {
        return (
            (filter.nom === '' || user.nom.toLowerCase().includes(filter.nom.toLowerCase())) &&
            (filter.status === '' || user.status === filter.status) &&
            (filter.role === '' || user.role === filter.role)
        );
    });

    // Pagination logic
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    // Handle pagination button click
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (loading) return <p>Chargement des utilisateurs...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="dashboard">
            <main className="main-content">
                <div className="header">
                    <h1>Gestion des Utilisateurs</h1>
                    <button className="btn btn-primary2" onClick={handleModalToggle}>
                        <i className="mdi mdi-plus"></i>
                        Ajouter un utilisateur
                    </button>
                </div>

                <div className="search-bar">
                    <input 
                        type="text" 
                        className="search-input" 
                        placeholder="Rechercher un utilisateur..." 
                        value={filter.nom}
                        onChange={(e) => setFilter({ ...filter, nom: e.target.value })}
                    />
                    <select 
                        className="statusFilter"
                        value={filter.status}
                        onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                    >
                        <option value="">Status</option>
                        <option value={statusValues.ACTIVE}>{statusValues.ACTIVE}</option>
                        <option value={statusValues.PENDING}>{statusValues.PENDING}</option>
                        <option value={statusValues.EXPIRED}>{statusValues.EXPIRED}</option>
                    </select>
                    <select 
                        className="roleFilter"
                        value={filter.role}
                        onChange={(e) => setFilter({ ...filter, role: e.target.value })}
                    >
                        <option value="">Rôle</option>
                        <option value="admin">Admin</option>
                        <option value="adhérent">Adhérent</option>
                    </select>

                    <div className="page-size-selector">
                        <label htmlFor="pageSize">Nombre d'utilisateurs par page:</label>
                        <select id="pageSize" className="form-control" onChange={handlePageSizeChange}>
                            <option value="5">5</option>
                            <option value="10" selected>10</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                        </select>
                    </div>
                </div>

                <div className="users-table-container">
                    <table className="users-table">
                        <thead>
                            <tr>
                                <th>Utilisateur</th>
                                <th>Pseudo</th>
                                <th>Email</th>
                                <th>Rôle</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentUsers.map(user => (
                                <tr key={user._id}>
                                    <td>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                            {/*<img src={user.avatar || 'https://randomuser.me/api/portraits/men/1.jpg'} alt={user.nom} className="user-avatar" />*/}
                                            <span>{user.nom}</span>
                                        </div>
                                    </td>
                                    <td>{user.pseudo}</td>
                                    <td>{user.email}</td>
                                    <td>{user.role}</td>
                                    <td>
                                        <span className={`user-status status-${user.status === statusValues.ACTIVE ? 'active' : user.status === statusValues.EXPIRED ? 'expired' : user.status === statusValues.PENDING ? 'pending' : 'unknown'}`}>
                                            {user.status ? user.status : statusValues.UNKNOWN}
                                        </span>
                                    </td>
                                    <td>
                                        <div className="action-buttons">
                                            <button className="btn btn-sm btn-primary" onClick={() => handleEditUser(user)}>
                                                <i className="mdi mdi-pencil"></i>
                                            </button>
                                            <button className="btn btn-sm btn-warning" onClick={() => handleResetPassword(user._id)}>
                                                <i className="mdi mdi-key"></i>
                                            </button>
                                            <button className="btn btn-sm btn-danger" onClick={() => handleDeleteUser(user._id)}>
                                                <i className="mdi mdi-delete"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination controls */}
                <div className="pagination">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <span 
                            key={index + 1} 
                            className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </span>
                    ))}
                </div>
            </main>

            {isModalOpen && (
            <div className="modal-overlay">
                <div className="modal">
                    <div className="modal-header">
                        <h2>Ajouter un utilisateur</h2>
                        <button className="modal-close" onClick={handleModalToggle}>&times;</button>
                    </div>
                    <form id="addUserForm" onSubmit={(e) => { e.preventDefault(); handleAddUser(); }}>
                        <div className="modal-body">
                            <div className="form-group"><label>Nom</label>
                                <input type="text" name="nom" className="form-control" value={newUser.nom} onChange={handleInputChange} required />
                            </div>
                            <div className="form-group"><label>Pseudo</label>
                                <input type="text" name="pseudo" className="form-control" value={newUser.pseudo} onChange={handleInputChange} required />
                            </div>
                            <div className="form-group"><label>Email</label>
                                <input type="email" name="email" className="form-control" value={newUser.email} onChange={handleInputChange} required />
                            </div>
                            <div className="form-group"><label>Rôle</label>
                                <select name="role" className="form-control" value={newUser.role} onChange={handleInputChange} required>
                                    <option value="">Sélectionner un rôle</option>
                                    <option value="admin">Admin</option>
                                    <option value="adhérent">Adhérent</option>
                                </select>
                            </div>
                            <div className="form-group"><label>Mot de passe</label>
                                <input type={showPassword ? 'text' : 'password'} id="password" name="motDePasse" className="form-control" value={newUser.motDePasse} onChange={handleInputChange} required />
                                <button type="button" onClick={togglePasswordVisibility} style={{ marginLeft: '8px', background: 'none', border: 'none', cursor: 'pointer' }} aria-label={showPassword ? 'Cacher le mot de passe' : 'Afficher le mot de passe'}>
                                    <i className={`mdi ${showPassword ? 'mdi-eye-off' : 'mdi-eye'}`} style={{ fontSize: '24px', color: '#007bff' }}></i>
                                </button>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Ajouter</button>
                        </div>
                    </form>
                </div>
            </div>
        )}

            {isEditModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="modal-header">
                            <h2>Modifier l'utilisateur</h2>
                            <button className="modal-close" onClick={() => setEditModalOpen(false)}>&times;</button>
                        </div>
                        <form id="editUserForm" onSubmit={(e) => { e.preventDefault(); handleSaveChanges(); }}>
                            <div className="modal-body">
                                <div className="form-group"> <label htmlFor="nom">Nom</label>
                                    <input type="text" id="nom" name="nom" value={editingUser.nom} onChange={handleEditInputChange} required />
                                    
                                    <label htmlFor="pseudo">Pseudo</label>
                                    <input type="text" id="pseudo" name="pseudo" value={editingUser.pseudo} onChange={handleEditInputChange} required />
                                    
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id="email" name="email" value={editingUser.email} onChange={handleEditInputChange} required />
                                    
                                    {/*<label htmlFor="motDePasse">Mot de passe</label>
                                    <input type={showPassword ? 'text' : 'password'} id="motDePasse" name="motDePasse" value={editingUser.motDePasse} onChange={handleEditInputChange} required />
                                    */}
                                    <label htmlFor="role">Rôle</label>
                                    <select id="role" name="role" value={editingUser.role} onChange={handleEditInputChange} required>
                                        <option value="">Sélectionner un rôle</option>
                                        <option value="admin">Admin</option>
                                        <option value="adhérent">Adhérent</option>
                                    </select>
                                    
                                    <label htmlFor="status">Status</label>
                                    <select id="status" name="status" value={editingUser.status} onChange={handleEditInputChange} required>
                                        <option value="">Sélectionner un status</option>
                                        <option value="Valide">Valide</option>
                                        <option value="En attente">En attente</option>
                                    </select>
                                    </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Enregistrer les modifications</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

                {isAlertOpen && (
                    <AlertModal 
                        message={alertMessage} 
                        onClose={() => setAlertOpen(false)} 
                    />
                )}



        </div>
    );
};

export default UsersManagement;
