import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TeamCompositionModal.css';

const TeamCompositionModal = ({ show, eventId, teamIndex, onClose }) => {
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [teamBlack, setTeamBlack] = useState([]);
  const [teamBlue, setTeamBlue] = useState([]);
  const [currentTeamTurn, setCurrentTeamTurn] = useState(0);
  const [matchType, setMatchType] = useState(null);
  const [maxPlayersPerTeam, setMaxPlayersPerTeam] = useState(0);
  const [numberOfTeams, setNumberOfTeams] = useState(2);
  const [teamsLocked, setTeamsLocked] = useState(false); // Ajout de l'état teamsLocked
  

  // Ne charger les joueurs que lorsque le modal est affiché et qu'on a un eventId
  useEffect(() => {
    if (show && eventId) {
      fetchAvailablePlayers();
    }
  }, [show, eventId]);

  useEffect(() => {
  let interval;
  
  const refreshTeams = async () => {
    try {
      const eventResponse = await axios.get(`http://localhost:5000/api/events/${eventId}`);
      const event = eventResponse.data;
      
      // Mise à jour du tour actuel
      if (event.currentTeamTurn !== undefined) {
        setCurrentTeamTurn(event.currentTeamTurn);
      }

      // Récupération des joueurs à jour
      const playersResponse = await axios.get(`http://localhost:5000/api/event-subscriptions/${eventId}`);
      const players = playersResponse.data;

      if (event.teams && event.teams.length === 2) {
        // Mise à jour équipe noire
        const blackTeamMembers = event.teams[0].players.map(player => {
          const playerData = players.find(p => 
            p.user._id === (typeof player.user === 'string' ? player.user : player.user._id)
          );
          return playerData ? {
            ...playerData,
            isCapitain: player.isCapitain,
            teamColor: 'black'
          } : null;
        }).filter(Boolean);
        setTeamBlack(blackTeamMembers);

        // Mise à jour équipe bleue
        const blueTeamMembers = event.teams[1].players.map(player => {
          const playerData = players.find(p => 
            p.user._id === (typeof player.user === 'string' ? player.user : player.user._id)
          );
          return playerData ? {
            ...playerData,
            isCapitain: player.isCapitain,
            teamColor: 'blue'
          } : null;
        }).filter(Boolean);
        setTeamBlue(blueTeamMembers);

        // Mise à jour des joueurs disponibles
        const selectedPlayerIds = [
          ...event.teams[0].players,
          ...event.teams[1].players
        ].map(p => typeof p.user === 'string' ? p.user : p.user._id);

        const availablePlayers = players.filter(player => 
          !selectedPlayerIds.includes(player.user._id)
        );
        setAvailablePlayers(availablePlayers);
      }
    } catch (error) {
      console.error('Erreur lors du rafraîchissement des équipes:', error);
    }
  };

  if (show && eventId) {
    refreshTeams(); // Premier appel immédiat
    interval = setInterval(refreshTeams, 2000); // Rafraîchissement toutes les 2 secondes
  }

  return () => {
    if (interval) {
      clearInterval(interval);
    }
  };
}, [show, eventId]);

  
  // Effet pour le rafraîchissement périodique
 /* useEffect(() => {
    let interval;
    
    const refreshTeams = async () => {
      try {
        const eventResponse = await axios.get(`http://localhost:5000/api/events/${eventId}`);
        console.log('Rafraîchissement des équipes:', eventResponse.data);
        const event = eventResponse.data;
        
        if (event.currentTeamTurn !== undefined) {
          setCurrentTeamTurn(event.currentTeamTurn);
        }
  
        // Vérification si les équipes sont complètes
        if (event.teams && event.teams.length === 2) {
          console.log('État des équipes:', {
            equipeNoire: event.teams[0].players.length,
            equipeBleue: event.teams[1].players.length,
            maxJoueurs: maxPlayersPerTeam
          });
  
          const isTeamsComplete = 
            event.teams[0].players.length === maxPlayersPerTeam && 
            event.teams[1].players.length === maxPlayersPerTeam;
  
          console.log('Équipes complètes?', isTeamsComplete);
          console.log('Index équipe actuelle:', teamIndex);

          // Si les équipes sont complètes, mettre à jour teamConfiguration
          if (isTeamsComplete && !event.teamConfiguration?.isConfigured) {
            await axios.put(`http://localhost:5000/api/events/${eventId}/teams`, {
              teams: event.teams,
              currentTeamTurn: event.currentTeamTurn,
              teamConfiguration: {
                isConfigured: true
              }
            });
            console.log('teamConfiguration.isConfigured mis à jour à true');
          }
  
          // Mise à jour des équipes d'abord
          const playersResponse = await axios.get(`http://localhost:5000/api/event-subscriptions/${eventId}`);
          const players = playersResponse.data;
  
          // Traitement équipe bleue
          const blueTeamMembers = event.teams[1].players
            .map(player => {
              const playerData = players.find(p => p.user._id === player.user);
              if (!playerData) return null;
              return {
                ...playerData,
                isCapitain: player.isCapitain,
                teamColor: 'blue'
              };
            })
            .filter(Boolean);
          setTeamBlue(blueTeamMembers);
  
          // Attendre que l'interface soit mise à jour
          setTimeout(() => {
            if (isTeamsComplete && teamIndex === 0) {
              const alertShown = localStorage.getItem(`teamCompleteAlertShown_${teamIndex}`);
              console.log('Alerte déjà affichée?', alertShown ? 'Oui' : 'Non');
              
              if (!alertShown) {
                console.log('Préparation affichage alerte pour capitaine noir');
                clearInterval(interval);
                //alert(`Les équipes sont complètes (${maxPlayersPerTeam} joueurs par équipe).`);
                console.log('Alerte affichée pour capitaine noir');
                localStorage.setItem(`teamCompleteAlertShown_${teamIndex}`, 'true');
                //onClose();
              }
            }
          }, 500); // Délai de 500ms pour laisser le temps à l'interface de se mettre à jour
        }
      
      // Récupération des joueurs
      const playersResponse = await axios.get(`http://localhost:5000/api/event-subscriptions/${eventId}`);
      const players = playersResponse.data;
      
      if (event.teams && event.teams.length === 2) {
        // Traitement équipe noire
        const blackTeamMembers = event.teams[0].players
          .map(player => {
            const playerData = players.find(p => p.user._id === player.user);
            if (!playerData) return null;
            return {
              ...playerData,
              isCapitain: player.isCapitain,
              teamColor: 'black'
            };
          })
          .filter(Boolean);
        setTeamBlack(blackTeamMembers);
        
        // Traitement équipe bleue
        const blueTeamMembers = event.teams[1].players
          .map(player => {
            const playerData = players.find(p => p.user._id === player.user);
            if (!playerData) return null;
            return {
              ...playerData,
              isCapitain: player.isCapitain,
              teamColor: 'blue'
            };
          })
          .filter(Boolean);
        setTeamBlue(blueTeamMembers);
        
        // Mise à jour des joueurs disponibles
        const selectedPlayerIds = [
          ...event.teams[0].players,
          ...event.teams[1].players
        ].map(p => p.user).filter(Boolean);
        
        const availablePlayers = players.filter(player => 
          !selectedPlayerIds.includes(player.user._id)
        );
        setAvailablePlayers(availablePlayers);

        // Vérification si les équipes sont complètes
        const isTeamsComplete = 
          blackTeamMembers.length === maxPlayersPerTeam && 
          blueTeamMembers.length === maxPlayersPerTeam;

        if (isTeamsComplete) {
          clearInterval(interval);
          await axios.patch(`http://localhost:5000/api/notifications/update-status`, {
            eventId: eventId,
            type: 'CAPTAIN_SELECTED',
            status: 'COMPLETED'
          });
        }
      }
    } catch (error) {
      console.error('Erreur lors du rafraîchissement des équipes:', error);
    }
  };

  if (show && eventId) {
    refreshTeams();
    interval = setInterval(refreshTeams, 2000);
  }

  return () => {
    if (interval) {
      clearInterval(interval);
    }
  };
}, [show, eventId, maxPlayersPerTeam, teamIndex]);*/

if (!show) return null;

  const determineMatchType = (totalPlayers) => {
    if (totalPlayers === 20) {
      return {
        type: '4teams',
        maxPlayers: 5,
        teams: 4
      };
    } else if (totalPlayers >= 12) {
      return {
        type: '6v6',
        maxPlayers: 6,
        teams: 2
      };
    } else if (totalPlayers >= 10) {
      return {
        type: '5v5',
        maxPlayers: 5,
        teams: 2
      };
    }
    return null;
  };


 const fetchAvailablePlayers = async () => {
  try {
    console.log('Début fetchAvailablePlayers pour eventId:', eventId);

    // Fetch the event to get existing teams
    const eventResponse = await axios.get(`http://localhost:5000/api/events/${eventId}`);
    const event = eventResponse.data;
    console.log('Événement récupéré:', event);

    // Fetch subscribed players
    const response = await axios.get(`http://localhost:5000/api/event-subscriptions/${eventId}`);
    const players = response.data;
    console.log('Joueurs inscrits:', players);

    // Determine match configuration
    const matchConfig = determineMatchType(players.length);
    setMatchType(matchConfig.type);
    setMaxPlayersPerTeam(matchConfig.maxPlayers);
    setNumberOfTeams(matchConfig.teams);

    if (event.teams && event.teams.length === 2) {
      // Initialize black team members
      const blackTeamMembers = event.teams[0].players.map(player => {
        const playerData = players.find(p => p.user._id === (typeof player.user === 'string' ? player.user : player.user._id));
        return playerData ? {
          ...playerData,
          isCapitain: player.isCapitain,
          teamColor: 'black'
        } : null;
      }).filter(Boolean);
      
      setTeamBlack(blackTeamMembers);
      console.log('Équipe noire initialisée:', blackTeamMembers);

      // Initialize blue team members
      const blueTeamMembers = event.teams[1].players.map(player => {
        const playerData = players.find(p => p.user._id === (typeof player.user === 'string' ? player.user : player.user._id));
        return playerData ? {
          ...playerData,
          isCapitain: player.isCapitain,
          teamColor: 'blue'
        } : null;
      }).filter(Boolean);
      
      setTeamBlue(blueTeamMembers);
      console.log('Équipe bleue initialisée:', blueTeamMembers);

      // Collect IDs of players already in teams
      const teamPlayerIds = [
        ...event.teams[0].players,
        ...event.teams[1].players
      ].map(p => typeof p.user === 'string' ? p.user : p.user._id);

      // Filter available players
      const availablePlayers = players.filter(player => 
        !teamPlayerIds.includes(player.user._id)
      );

      console.log('Joueurs disponibles après filtrage:', availablePlayers);
      setAvailablePlayers(availablePlayers);
    } else {
      // If no teams exist, all players are available
      setAvailablePlayers(players);
    }

    // Set the current team turn
    setCurrentTeamTurn(event.currentTeamTurn || 0);

  } catch (error) {
    console.error('Erreur détaillée lors de la récupération des joueurs:', error);
    alert('Erreur lors de la récupération des joueurs');
  }
};

const handlePlayerSelection = async (player) => {
  try {
    console.log('Joueur sélectionné:', {
      nom: player.user.nom,
      id: player.user._id,
      isCapitain: player.isCapitain
    });

    if (currentTeamTurn !== teamIndex) {
      const equipeActive = currentTeamTurn === 0 ? 'noire' : 'bleue';
      const equipeActuelle = teamIndex === 0 ? 'noire' : 'bleue';
      alert(`C'est au tour de l'équipe ${equipeActive} de choisir un joueur (vous êtes l'équipe ${equipeActuelle})`);
      return;
    }

    const eventResponse = await axios.get(`http://localhost:5000/api/events/${eventId}`);
    const currentEvent = eventResponse.data;

    console.log('État initial des équipes:', currentEvent.teams.map((team, idx) => ({
      equipe: idx === 0 ? 'noire' : 'bleue',
      joueurs: team.players.map(p => ({
        id: p.user,
        isCapitain: p.isCapitain
      }))
    })));

    const updatedTeams = currentEvent.teams.map((team, idx) => {
      if (idx === teamIndex) {
        const existingPlayers = team.players;
        console.log(`Équipe ${idx === 0 ? 'noire' : 'bleue'} - Joueurs existants:`, 
          existingPlayers.map(p => ({
            id: p.user,
            isCapitain: p.isCapitain
          }))
        );

        const playerExists = existingPlayers.some(p => 
          p.user.toString() === player.user._id.toString()
        );

        if (!playerExists) {
          const newTeam = {
            ...team,
            players: [
              ...existingPlayers,
              {
                user: player.user._id,
                isCapitain: false
              }
            ]
          };

          console.log(`Équipe ${idx === 0 ? 'noire' : 'bleue'} - Après ajout:`, 
            newTeam.players.map(p => ({
              id: p.user,
              isCapitain: p.isCapitain
            }))
          );

          return newTeam;
        }
      }
      return team;
    });

    const nextTurn = currentTeamTurn === 0 ? 1 : 0;

    const updateResponse = await axios.put(`http://localhost:5000/api/events/${eventId}/teams`, {
      teams: updatedTeams,
      currentTeamTurn: nextTurn,
      teamConfiguration: {
        isConfigured: isTeamsComplete // true si les équipes sont complètes
      }
});

if (updateResponse.status === 200) {
  // Mise à jour de l'équipe noire ou bleue
  if (teamIndex === 0) {
    setTeamBlack(prevTeam => {
      const existingPlayers = prevTeam.filter(p => p.user._id !== player.user._id);
      const existingPlayer = prevTeam.find(p => p.user._id === player.user._id);
      return [...existingPlayers, {...player, isCapitain: existingPlayer?.isCapitain || false}];
    });
  } else {
    setTeamBlue(prevTeam => {
      const existingPlayers = prevTeam.filter(p => p.user._id !== player.user._id);
      const existingPlayer = prevTeam.find(p => p.user._id === player.user._id);
      return [...existingPlayers, {...player, isCapitain: existingPlayer?.isCapitain || false}];
    });
  }

  // Mise à jour des joueurs disponibles
  setAvailablePlayers(prevPlayers => 
    prevPlayers.filter(p => p.user._id !== player.user._id)
  );

  // Changement de tour
  setCurrentTeamTurn(nextTurn);

  // Vérifier si les équipes sont complètes
  const updatedTeamBlack = teamIndex === 0 ? 
    [...teamBlack, player] : 
    teamBlack;
  const updatedTeamBlue = teamIndex === 1 ? 
    [...teamBlue, player] : 
    teamBlue;

  const isTeamsComplete = 
    updatedTeamBlack.length === maxPlayersPerTeam && 
    updatedTeamBlue.length === maxPlayersPerTeam;

  if (isTeamsComplete) {
    await handleTeamsComplete(updatedTeamBlack, updatedTeamBlue);
  }
} else {
  throw new Error('La mise à jour des équipes a échoué');
}

   
  } catch (error) {
    console.error('Erreur détaillée lors de la sélection du joueur:', error);
    alert('Erreur lors de la sélection du joueur');
  }
   

  };

// Fonction auxiliaire pour gérer les notifications
const handleTeamsComplete = async (teamBlack, teamBlue) => {
  try {
    const team1Captain = teamBlack.find(p => p.isCapitain);
    const team2Captain = teamBlue.find(p => p.isCapitain);

    if (!team1Captain || !team2Captain) return;

    // Mettre à jour teamConfiguration.isConfigured
    await axios.put(`http://localhost:5000/api/events/${eventId}/teams`, {
      teams: [
        { players: teamBlack.map(p => ({ user: p.user._id, isCapitain: p.isCapitain })) },
        { players: teamBlue.map(p => ({ user: p.user._id, isCapitain: p.isCapitain })) }
      ],
      teamConfiguration: {
        isConfigured: true
      }
    });

    // Mise à jour des notifications existantes
    await Promise.all([
      axios.patch(`http://localhost:5000/api/notifications/update-status`, {
        eventId,
        type: 'CAPTAIN_SELECTED',
        status: 'COMPLETED',
        teamIndex: 0
      }),
      axios.patch(`http://localhost:5000/api/notifications/update-status`, {
        eventId,
        type: 'CAPTAIN_SELECTED',
        status: 'COMPLETED',
        teamIndex: 1
      })
    ]);

    // Création des nouvelles notifications
    await Promise.all([
      axios.post(`http://localhost:5000/api/notifications/team-complete`, {
        eventId,
        userId: team1Captain.user._id,
        teamIndex: 0,
        maxPlayers: maxPlayersPerTeam
      }),
      axios.post(`http://localhost:5000/api/notifications/team-complete`, {
        eventId,
        userId: team2Captain.user._id,
        teamIndex: 1,
        maxPlayers: maxPlayersPerTeam
      })
    ]);
  } catch (error) {
    console.error('Erreur lors de la mise à jour des notifications:', error);
  }
};

const saveTeamComposition = async () => {
  try {
    // Récupérer l'événement actuel pour avoir les équipes existantes
    const eventResponse = await axios.get(`http://localhost:5000/api/events/${eventId}`);
    const event = eventResponse.data;

    // Récupérer les capitaines existants
    const team1Captain = event.teams?.[0]?.players.find(p => p.isCapitain);
    const team2Captain = event.teams?.[1]?.players.find(p => p.isCapitain);

    // Créer les tableaux de joueurs pour chaque équipe en conservant les capitaines
    const blackTeamPlayers = teamBlack.map(p => ({
      user: p.user._id,
      isCapitain: team1Captain?.user === p.user._id ? true : false
    }));

    const blueTeamPlayers = teamBlue.map(p => ({
      user: p.user._id,
      isCapitain: team2Captain?.user === p.user._id ? true : false
    }));

    // Utiliser PUT pour mettre à jour les équipes
    const response = await axios.put(`http://localhost:5000/api/events/${eventId}/teams`, {
      ...event,
      teams: [
        { players: blackTeamPlayers },
        { players: blueTeamPlayers }
      ]
    });

    // Vérifier si les deux équipes sont complètes
const isTeamsComplete = 
  (teamBlack?.length || 0) === maxPlayersPerTeam && 
  (teamBlue?.length || 0) === maxPlayersPerTeam;

    // Ne mettre à jour le statut que si les équipes sont complètes
    if (isTeamsComplete) {
      await handleTeamsComplete(teamBlack, teamBlue);
      // Mise à jour du statut CAPTAIN_SELECTED
      await axios.patch(`http://localhost:5000/api/notifications/update-status`, {
        eventId: eventId,
        type: 'CAPTAIN_SELECTED',
        status: 'COMPLETED'
      });
      console.log('Composition des équipes terminée');
    

    // Vérifier si teamConfiguration.isConfigured a été mis à jour
    if (response.data.teamConfiguration?.isConfigured) {
      console.log('Configuration des équipes terminée et verrouillée');
      // Mettre à jour l'état local si nécessaire
      setTeamsLocked(true);
    }
  }

   
    onClose();
  } catch (error) {
    console.error('Erreur lors de la sauvegarde de la composition:', error);
  }
};

// Simplifier handleClose
const handleClose = () => {
  onClose();
};


// Calculer le nombre de joueurs disponibles (non sélectionnés)
const availablePlayersCount = availablePlayers?.filter(player => {
  if (!teamBlack || !teamBlue) return true;
  const inBlackTeam = teamBlack.some(p => p.user._id === player.user._id);
  const inBlueTeam = teamBlue.some(p => p.user._id === player.user._id);
  return !inBlackTeam && !inBlueTeam;
})?.length || 0;
  
// Ajoutez ces fonctions pour obtenir les noms des capitaines
const getTeamCaptainName = (team) => {
    const captain = team.find(player => player.isCapitain);
    return captain ? `${captain.user.nom}` : 'Non désigné';
  };

  // Ajouter cette constante avant le return
const isTeamsComplete = 
  (teamBlack?.length || 0) === maxPlayersPerTeam && 
  (teamBlue?.length || 0) === maxPlayersPerTeam;

  return (
    
    <div className={`modal-overlay ${show ? 'show' : ''}`}>
                <div className="modal-content">
                <div className="modal-header">
                    <h3>Composition des équipes</h3>
                    <button onClick={handleClose} className="close-btn">
                    <i className="mdi mdi-close"></i>
                    </button>
                </div>
                {/* Information du type de match déplacée ici, juste après le header */}
                  {matchType && (
                    <div className="match-info-container">
                      <div className="match-type-info">
                        <div className="match-type-row">
                          <span className="match-label">Type de match :</span>
                          <span className="match-value">
                            {matchType === '5v5' ? '5 vs 5' :
                            matchType === '6v6' ? '6 vs 6' :
                            'Tournoi 4 équipes'}
                          </span>
                        </div>
                        <div className="match-type-row">
                          <span className="match-label">Joueurs par équipe :</span>
                          <span className="match-value">{maxPlayersPerTeam}</span>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="teams-container">
                {/* Équipe Noire */}
                <div className="team black">
                  <h4>Équipe Noire ({teamBlack?.length || 0}/{maxPlayersPerTeam} joueurs)</h4>
                    {(teamBlack || []).map((player, index) => (
                    <div 
                    key={`black-${player.user._id}-${index}`} 
                        className={`player-item selected ${player.isCapitain ? 'captain' : ''} ${teamsLocked ? 'locked' : ''}`}
                    >
                        <span>{player.user.nom} </span>
                          {player.isCapitain && (
                            <span className="captain-badge">
                              Capitaine {teamsLocked ? '(verrouillé)' : ''}
                        </span>
                          )}
                    </div>
                    ))}
                </div>

                {/* Équipe Bleue */}
                <div className="team blue">
                  <h4>Équipe Bleue ({teamBlue?.length || 0}/{maxPlayersPerTeam} joueurs)</h4>
                    {(teamBlue || []).map((player, index) => (
                    <div 
                    key={`black-${player.user._id}-${index}`} 
                        className={`player-item selected ${player.isCapitain ? 'captain' : ''} ${teamsLocked ? 'locked' : ''}`}
                    >
                        <span>{player.user.nom}</span>
                          {player.isCapitain && (
                            <span className="captain-badge">
                              Capitaine {teamsLocked ? '(verrouillé)' : ''}
                        </span>
                          )}
                    </div>
                    ))}
                </div>
                </div>

                 {/* Liste des joueurs disponibles avec le compteur mis à jour */}
                <div className="available-players-section">
                    <h4>Joueurs disponibles ({availablePlayersCount} joueurs)</h4>
                    <div className="players-grid">
                        {(availablePlayers || []).map((player, index) => {
                            const isSelected = 
                                (teamBlack || []).some(p => p.user._id === player.user._id) || 
                                (teamBlue || []).some(p => p.user._id === player.user._id);
                            
                            return (
                                <div 
                                    key={`available-${player.user._id}-${index}`}
                                    className={`player-item 
                                        ${currentTeamTurn === 0 ? 'black-turn' : 'blue-turn'} 
                                        ${player.isCapitain ? 'captain' : ''}
                                        ${isSelected ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (!isSelected && !teamsLocked) {
                                            if (currentTeamTurn === teamIndex) {
                                                const targetTeam = teamIndex === 0 ? teamBlack : teamBlue;
                                                if ((targetTeam?.length || 0) < maxPlayersPerTeam) {
                                                    handlePlayerSelection(player);
                                                } else {
                                                    alert(`L'équipe ${teamIndex === 0 ? 'noire' : 'bleue'} est complète (${maxPlayersPerTeam} joueurs maximum)`);
                                                }
                                            } else {
                                                const equipeActive = currentTeamTurn === 0 ? 'noire' : 'bleue';
                                                const equipeCliquante = teamIndex === 0 ? 'noire' : 'bleue';
                                                alert(`C'est au tour de l'équipe ${equipeActive} de choisir un joueur...`);
                                            }
                                        }
                                    }}
                                >
                                    <span>{player.user.nom}</span>
                                    {player.isCapitain && (
                                        <span className="captain-badge">
                                            Capitaine
                                        </span>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>

                 {/*<div className={`turn-indicator ${currentTeamTurn === 0 ? 'black-turn' : 'blue-turn'}`}>
                    Au tour de : {currentTeamTurn === 0 ? 'l\'équipe Noire' : 'l\'équipe Bleue'}
                </div>*/}

                <div className={`turn-indicator ${
                    isTeamsComplete 
                        ? 'teams-complete' 
                        : currentTeamTurn === 0 
                            ? 'black-turn' 
                            : 'blue-turn'
                }`}>
                    {isTeamsComplete 
                        ? `Les équipes sont complètes (${maxPlayersPerTeam} joueurs par équipe).`
                        : `Au tour de : ${currentTeamTurn === 0 ? 'l\'équipe Noire' : 'l\'équipe Bleue'}`
                    }
                </div>

                <div className="modal-footer">
                  <button 
                    className="cancel-btn"
                    onClick={handleClose}
                  >
                    Fermer
                  </button>
                </div>
            </div>
            
        </div>

  );
};

export default TeamCompositionModal;