import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Auth from './components/Auth'; // Vérifie que le chemin est correct
import Home from './components/Accueil'; // Assure-toi que le chemin est correct
import UserDashboard from './components/UserDashboard'; // Nouveau
import AdminDashboard from './components/AdminDashboard'; // Nouveau
import '@mdi/font/css/materialdesignicons.min.css';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/user-dashboard" element={<UserDashboard />} /> {/* Nouveau */}
        <Route path="/admin-dashboard" element={<AdminDashboard />} /> {/* Nouveau */}

        {/* Ajoutez d'autres routes ici si nécessaire */}
      </Routes>
    </Router>
  );
};

export default App;
