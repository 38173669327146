// src/components/EventCard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EventCard.css';

// Déclaration des images en utilisant require pour l'import dynamique
const sportImages = {
    Football: require('../assets/images/football-image.jpg'),
    Padel: require('../assets/images/padel-image.jpg'),
};

const EventCard = ({ event, sportName, onDelete, onEdit, onDetails, onResolveDispute   }) => {
    const [subscribersCount, setSubscribersCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    


    const sportIcon = sportName === 'Football' ? 'mdi-soccer' : sportName === 'Padel' ? 'mdi-tennis' : '';
    const sportImage = sportImages[sportName];

    // Vérifier si l'événement est en litige
    const isDisputed = event.tempScores?.some(score => score.status === 'DISPUTED');
    


    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'À venir':
                return 'status-upcoming';
            case 'En cours':
                return 'status-ongoing';
            case 'Terminé':
                return 'status-completed';
            default:
                return '';
        }
    };

    const handleDetails = () => {
        if (onDetails) {
            onDetails(event);
        }
    };

    const handleDelete = () => {
        if (onDelete) {
            onDelete(event._id); // Appelle onDelete avec l'ID de l'événement
        }
    };

    const handleEdit = () => {
        if (onEdit) {
            onEdit(event); // Appelle onEdit avec les données de l'événement
        }
    };

 // Récupérer le nombre d'inscrits pour l'événement
    const fetchSubscribersCount = async () => {
        try {
            const response = await axios.get(`http://localhost:5000/api/event-subscriptions/count/${event._id}`);
            setSubscribersCount(response.data.count);
        } catch (error) {
            console.error('Erreur lors de la récupération du nombre d\'inscrits:', error);
            setErrorMessage('Erreur lors de la récupération du nombre d\'inscrits');
        }
    };

     // Appeler fetchSubscribersCount au chargement du composant
     useEffect(() => {
        fetchSubscribersCount();
    }, [event._id]);

    return (
    <div className={`event-card ${isDisputed ? 'disputed' : ''}`}>
        {isDisputed && (
            <div className="dispute-badge">
                <i className="mdi mdi-alert-circle"></i>
                Litige
            </div>
        )}
        {sportImage && <img src={sportImage} alt={sportName} className="event-image" />}
        <div className="event-details">
            <div className="event-title-container">
                <h3 className="event-title">{event.title}</h3>
                <span className={`status-badge ${getStatusClass(event.status)}`}>
                    {event.status}
                </span>
            </div>
            <div className="event-type">
                {sportIcon && <i className={`mdi ${sportIcon}`}></i>} {sportName}
            </div>
            <div className="event-meta">
                <span><i className="mdi mdi-calendar"></i> {formatDate(event.date)}</span>
                <span><i className="mdi mdi-map-marker"></i> {event.location}</span>
            </div>
            <div className="event-stats">
                <div className="stat">
                    <div className="stat-value">16</div>
                    <div className="stat-label">Équipes</div>
                </div>
                <div className="stat">
                    <div className="stat-value">{subscribersCount}</div>
                    <div className="stat-label">Participants</div>
                </div>
            </div>
            <div className="event-actions">
                <button className="btn btn-primary" onClick={handleDetails}>
                    <i className="mdi mdi-eye"></i> Détails
                </button>
                
                {isDisputed ? (
                    <button 
                        className="btn btn-warning dispute-resolution" 
                        onClick={() => onResolveDispute(event)}
                    >
                        <i className="mdi mdi-gavel"></i> Résoudre le litige
                    </button>
                ) : (
                    <>
                        <button className="btn btn-warning" onClick={handleEdit}>
                            <i className="mdi mdi-pencil"></i> Modifier
                        </button>
                        <button className="btn btn-danger" onClick={handleDelete}>
                            <i className="mdi mdi-delete"></i> Supprimer
                        </button>
                    </>
                )}
            </div>
        </div>
    </div>
);
};

export default EventCard;
