/*import React, { useEffect } from 'react';
import { useAuth } from '../AuthContext'; // Utilisation du hook pour accéder au contexte
import { useNavigate } from 'react-router-dom'; // Importation de useNavigate
import './Dashboard.css'; // Dans UserDashboard.js et AdminDashboard.js

const Dashboard = () => {
  const { user, isAuthenticated, logout } = useAuth(); // Accéder aux données d'authentification
  const navigate = useNavigate(); // Initialisation de useNavigate

  useEffect(() => {
    // Logique pour gérer ce qui doit se passer lorsque le composant est monté
    if (!isAuthenticated) {
      // Rediriger l'utilisateur vers la page d'authentification
      navigate('/auth'); // Change cette route selon tes besoins
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = () => {
    logout(); // Appelle la fonction de déconnexion
    navigate('/auth'); // Redirige vers la page d'authentification après la déconnexion
  };

  return (
    <div>
      <h1>Tableau de bord</h1>
      {isAuthenticated ? (
        <div>
          <h2>Bienvenue, {user.prenom} {user.nom}!</h2>
          <button onClick={handleLogout}>Déconnexion</button> 
        </div>
      ) : (
        <h2>Vous devez vous connecter pour accéder à votre tableau de bord.</h2>
      )}
    </div>
  );
};

export default Dashboard;*/
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import axios from 'axios';

const Dashboard = () => {
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [planningData, setPlanningData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchTime, setSearchTime] = useState('');
  const [filteredCreneauxLibres, setFilteredCreneauxLibres] = useState(0);
  const [terrainsLibres, setTerrainsLibres] = useState([]);
  const [creneaux12hSemaine, setCreneaux12hSemaine] = useState([]);
  const [isLoading12h, setIsLoading12h] = useState(false);
  

  // Créer une instance axios avec une configuration persistante
const axiosInstance = axios.create({
  baseURL: 'http://localhost:5000',
  withCredentials: true // Maintient les cookies de session
});

// Hook personnalisé pour gérer les créneaux
const useCreneaux = () => {
  const [creneaux12hSemaine, setCreneaux12hSemaine] = useState([]);
  const [isLoading12h, setIsLoading12h] = useState(false);

  const fetchCreneaux12hSemaine = useCallback(async () => {
    setIsLoading12h(true);
    const resultats = [];
    const dates = getNextSevenDays();

    try {
      // Utiliser Promise.all pour paralléliser les requêtes
      const promises = dates.map(async (date) => {
        const formattedDate = date.toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).replace(/\//g, '-');

        const response = await axiosInstance.post('/api/scrape', { date: formattedDate });

        if (response.data.success) {
          const creneaux = response.data.data.creneaux;
          const terrainsLibres = [];

          if (creneaux['12H00']) {
            Object.entries(creneaux['12H00']).forEach(([nomTerrain, data]) => {
              if (data.statut.toUpperCase() === 'LIBRE') {
                terrainsLibres.push(nomTerrain);
              }
            });
          }

          return {
            date: date.toLocaleDateString('fr-FR', {
              weekday: 'long',
              day: 'numeric',
              month: 'long'
            }),
            terrainsLibres,
            nombreLibres: terrainsLibres.length
          };
        }
      });

      const results = await Promise.all(promises);
      setCreneaux12hSemaine(results.filter(Boolean));
    } catch (error) {
      console.error('Erreur lors de la récupération des créneaux de 12h:', error);
    } finally {
      setIsLoading12h(false);
    }
  }, []);

  return { creneaux12hSemaine, isLoading12h, fetchCreneaux12hSemaine };
};

  // Ajoutez cette fonction pour calculer les créneaux libres filtrés
  const calculateFilteredCreneaux = (creneaux, searchTime) => {
    console.log('Début calcul - Heure recherchée:', searchTime);
  
    if (!searchTime || !creneaux) {
      return { count: 0, terrainsLibres: [] };
    }
  
    const searchTimeFormatted = searchTime.replace(':00', 'H00');
    let count = 0;
    let terrainsLibres = [];
    
    if (creneaux[searchTimeFormatted]) {
      const terrains = creneaux[searchTimeFormatted];
      
      Object.entries(terrains).forEach(([nomTerrain, data]) => {
        if (data.statut.toUpperCase() === 'LIBRE') {
          count++;
          terrainsLibres.push(nomTerrain);
        }
      });
    }
  
    console.log('Terrains libres trouvés:', terrainsLibres);
    return { count, terrainsLibres };
  };

// Ajoutez ce useEffect pour mettre à jour les créneaux filtrés
useEffect(() => {
  if (planningData?.creneaux) {
    const { count, terrainsLibres } = calculateFilteredCreneaux(planningData.creneaux, searchTime);
    setFilteredCreneauxLibres(count);
    setTerrainsLibres(terrainsLibres);
  }
}, [searchTime, planningData]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth');
    } else {
      handleScrape(selectedDate);
    }
  }, [isAuthenticated, navigate]);

  const getNextSevenDays = () => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    return dates;
  };

  // Ajoutez cette nouvelle fonction
const fetchCreneaux12hSemaine = async () => {
  setIsLoading12h(true);
  const resultats = [];
  const dates = getNextSevenDays();

  try {
    for (const date of dates) {
    
      const formattedDate = date.toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).replace(/\//g, '-');

      const response = await axios.post(`http://localhost:5000/api/scrape?date=${formattedDate}`);

      if (response.data.success) {
        const creneaux = response.data.data.creneaux;
        const terrainsLibres = [];

        if (creneaux['12H00']) {
          Object.entries(creneaux['12H00']).forEach(([nomTerrain, data]) => {
            if (data.statut.toUpperCase() === 'LIBRE') {
              terrainsLibres.push(nomTerrain);
            }
          });
        }

        resultats.push({
          date: date.toLocaleDateString('fr-FR', {
            weekday: 'long',
            day: 'numeric',
            month: 'long'
          }),
          terrainsLibres,
          nombreLibres: terrainsLibres.length
        });
      }
    }
    setCreneaux12hSemaine(resultats);
  } catch (error) {
    console.error('Erreur lors de la récupération des créneaux de 12h:', error);
  } finally {
    setIsLoading12h(false);
  }
};


  const handleScrape = async (date) => {
    try {
      setIsLoading(true);
      setError(null);

      const today = new Date();
      const isToday = date.toDateString() === today.toDateString();
      
      const formattedDate = date.toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).replace(/\//g, '-');
      
      // Utiliser l'URL relative pour l'API
      const apiUrl = isToday 
      ? 'http://localhost:5000/api/scrape'
      : `http://localhost:5000/api/scrape?date=${formattedDate}`;
    
      const response = await axios.post(apiUrl);

      if (response.data.success) {
        setPlanningData(response.data.data);
      } else {
        throw new Error(response.data.error || 'Erreur lors de la récupération des données');
      }
    } catch (error) {
      setError(error.message);
      setPlanningData(null);
    } finally {
      setIsLoading(false);
    }
  };

  // Ajoutez cette fonction pour générer les heures pleines
const getHeuresPleines = () => {
  const heures = [];
  for (let i = 8; i <= 22; i++) { // De 8h à 22h
    heures.push(`${i.toString().padStart(2, '0')}:00`);
  }
  return heures;
};

  const renderDateTabs = () => {
    const dates = getNextSevenDays();
    
    return (
      <div className="date-tabs">
        {dates.map((date) => (
          <button
            key={date.toISOString()}
            className={`date-tab ${date.toDateString() === selectedDate.toDateString() ? 'active' : ''}`}
            onClick={() => {
              setSelectedDate(date);
              handleScrape(date);
            }}
          >
            {date.toLocaleDateString('fr-FR', {
              weekday: 'short',
              day: 'numeric',
              month: 'short'
            })}
          </button>
        ))}
      </div>
    );
  };

  // Ajoutez ce composant de recherche juste avant le renderPlanning()
  /*const renderSearchBar = () => {
    return (
      <div className="search-container">
        <select
          value={searchTime}
          onChange={(e) => setSearchTime(e.target.value)}
          className="time-search"
        >
          <option value="">Sélectionner une heure</option>
          {getHeuresPleines().map((heure) => (
            <option key={heure} value={heure}>
              {heure}
            </option>
          ))}
        </select>
        {searchTime && (
          <div className="filtered-stats">
            <p>Créneaux libres à {searchTime}: {filteredCreneauxLibres}</p>
            {terrainsLibres.length > 0 && (
              <p className="terrains-libres">
                Terrains disponibles: {terrainsLibres.join(', ')}
              </p>
            )}
          </div>
        )}
      </div>
    );
  };*/

  const renderPlanning = () => {
    if (!planningData) return null;

    const { date, terrains, creneaux } = planningData;
    const horaires = Object.keys(creneaux);
    const terrainsArray = Object.values(terrains);

    return (
      <div className="planning-container">
        <h3>Planning du {date}</h3>
        <table className="planning-table">
          <thead>
            <tr>
              <th>Horaire</th>
              {terrainsArray.map(terrain => (
                <th key={terrain.id}>{terrain.nom}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {horaires.map(horaire => (
              <tr key={horaire}>
                <td>{horaire}</td>
                {terrainsArray.map(terrain => {
                  const creneau = creneaux[horaire][terrain.nom];
                  return (
                    <td 
                      key={`${horaire}-${terrain.id}`}
                      className={`status-${creneau.statut.toLowerCase()}`}
                    >
                      {creneau.statut}
                      {creneau.reservation && (
                        <div className="reservation-info">
                          {/*<small>Coût: {creneau.reservation.cout}</small>*/}
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Ajoutez ce composant pour afficher les résultats
const renderCreneaux12hSemaine = () => {
  if (isLoading12h) {
    return <div className="loading">Chargement des créneaux de 12h...</div>;
  }

  return (
    <div className="creneaux-12h-semaine">
      <h3>Disponibilités à 12h00 pour la semaine</h3>
      <div className="creneaux-grid">
        {creneaux12hSemaine.map((jour, index) => (
          <div key={index} className="jour-card">
            <h4>{jour.date}</h4>
            {jour.nombreLibres > 0 ? (
              <>
                <p className="nombre-libres">{jour.nombreLibres} terrain(s) libre(s)</p>
                <p className="terrains-liste">{jour.terrainsLibres.join(', ')}</p>
              </>
            ) : (
              <p className="aucun-libre">Aucun terrain libre</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

// Ajoutez un bouton pour déclencher la recherche
const renderSearchButton12h = () => (
  <button 
    className="search-12h-button"
    onClick={fetchCreneaux12hSemaine}
    disabled={isLoading12h}
  >
    Voir les créneaux de 12h00 sur 7 jours
  </button>
);



  return (
    <div className="dashboard-container">
      <h1>Tableau de bord</h1>
      {isAuthenticated && (
        <div>
          <h2>Bienvenue, {user.prenom} {user.nom}!</h2>
          
          {renderDateTabs()}
          {/*{renderSearchBar()}*/}
          {renderSearchButton12h()} {/* Ajoutez le bouton ici */}
          
          {isLoading && <div className="loading">Chargement...</div>}
          {error && <div className="error-message">{error}</div>}
          
          {renderCreneaux12hSemaine()} {/* Ajoutez l'affichage des créneaux 12h */}
          {renderPlanning()}
        </div>
      )}
    </div>
  );
};

export default Dashboard;