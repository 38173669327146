import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './UserMainDashboard.css';
import TeamCompositionModal from './TeamCompositionModal';
import TeamCompositionWithScore from './TeamCompositionWithScore';
import SubscriptionCard from './SubscriptionCard';
import ScoreModal from './ScoreModal';
import UserSidebar from './UserSidebar';
import UserProfile from './UserProfile';
import FormulasList from './FormulasList';
import EventsList from './EventsList';
import StatsCard from './StatsCard';



const UserMainDashboard = () => {
  const { user, isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  const [events, setEvents] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [eventSubscriptions, setEventSubscriptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showSubscribersModal, setShowSubscribersModal] = useState(false);
  const [selectedEventSubscribers, setSelectedEventSubscribers] = useState([]);
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [selectedEventTitle, setSelectedEventTitle] = useState('');
  const [pastEvents, setPastEvents] = useState([]);
  const [showEventDetails, setShowEventDetails] = useState(false); 
  const [notifications, setNotifications] = useState([]);
  const [showTeamCompositionModal, setShowTeamCompositionModal] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(null);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [isBlueTeam, setIsBlueTeam] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState([]); 
  const [canSubmitScore, setCanSubmitScore] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);


  const [activeView, setActiveView] = useState('user-dashboard');

  const handleShowDashboard = () => setActiveView('user-dashboard');
  const handleShowSubscriptions = () => setActiveView('subscriptions');
  const handleShowFormulas = () => setActiveView('formulas');
  const handleShowEvents = () => setActiveView('events');
  const handleShowProfil = () => setActiveView('profil');



  const disputedEventIds = useRef(new Set());


  const sportImages = {
    Football: require('../assets/images/football-image.jpg'),
    Padel: require('../assets/images/padel-image.jpg'),
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (isAuthenticated && user?._id) {
      fetchUserSubscriptions();
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
  if (isAuthenticated && user?._id) {
    // Rafraîchir les notifications périodiquement
    const notificationInterval = setInterval(async () => {
      try {
        const response = await axios.get(`http://localhost:5000/api/notifications/${user._id}`);
        if (response.data) {
          // Trier les notifications par date
          const sortedNotifications = response.data.sort((a, b) => 
            new Date(b.createdAt) - new Date(a.createdAt)
          );

          //console.log('notifications:',sortedNotifications);
          
          // Mettre à jour les notifications sans déclencher de rafraîchissement
          setNotifications(sortedNotifications);
        }
      } catch (error) {
        console.error('Erreur lors du rafraîchissement des notifications:', error);
      }
    }, 5000);

    // Nettoyage à la destruction du composant
    return () => clearInterval(notificationInterval);
  }
}, [isAuthenticated, user]);

useEffect(() => {
  const checkScoreValidation = async () => {
       
    if (!user?._id) {
        return;
    }

    try {
      const response = await axios.get(`http://localhost:5000/api/notifications/${user._id}`);
      const notifications = response.data;

      const hasNewValidation = notifications.some(notif => 
        notif.type === 'SCORE_VALIDATED' && !notif.read
      );

      if (hasNewValidation) {
        
        await Promise.all([
          fetchUpcomingEvents(),
          fetchPastEvents()
        ]);
        
      }
    } catch (error) {
      console.error('checkScoreValidation - Erreur:', error);
    }
  };

  // Mettre en place un intervalle de vérification
  const interval = setInterval(checkScoreValidation, 5000);

  // Nettoyage à la destruction du composant
  return () => clearInterval(interval);
}, [user?._id]);

  useEffect(() => {
    if (isAuthenticated && user?._id && subscriptions.length > 0) {
      fetchNotifications();
      fetchUpcomingEvents();
      fetchPastEvents();
    }
  }, [isAuthenticated, user, subscriptions]);

  useEffect(() => {
    if (events.length > 0) {
      events.forEach(event => {
        checkSubscriptionStatus(event._id);
      });
    }
  }, [events]);

  useEffect(() => {
    if (!showTeamCompositionModal && user?._id) {
      refreshNotifications();
    }
  }, [showTeamCompositionModal, user?._id]);

  useEffect(() => {
  if (showTeamCompositionModal && !validateTeamIndex(selectedTeamIndex)) {
    console.error('Modal ouvert avec un index d\'équipe invalide');
    setShowTeamCompositionModal(false);
    setErrorMessage('Erreur: index d\'équipe invalide');
  }
}, [showTeamCompositionModal, selectedTeamIndex]);

useEffect(() => {
  if (!showScoreModal && selectedEventDetails) {
    fetchUpcomingEvents();
    fetchPastEvents();
    setSelectedEventDetails(null);
  }
}, [showScoreModal]);



// Ajouter un useEffect pour surveiller les changements de tempScores
useEffect(() => {
  const checkDisputeStatus = () => {
    if (!events) return;

    events.forEach(event => {
      const hasDispute = event.tempScores?.some(score => score.status === 'DISPUTED');
      
      console.log('Vérification litige:', {
        eventId: event._id,
        hasDispute,
        tempScores: event.tempScores
      });
    });
  };

  checkDisputeStatus();
}, [events]);

// Ajouter un useEffect pour surveiller les changements de notifications
useEffect(() => {
  const checkDisputeNotifications = async () => {
    // Vérifier si une notification de litige existe
    const hasDispute = notifications.some(
      notif => notif.type === 'SCORE_DISPUTE'
    );

    // Vérifier si une notification de score validé existe
    const isScoreCompleted = notifications.some(
      notif => notif.type === 'SCORE_VALIDATED'
    );

   
  };

  checkDisputeNotifications();
}, [notifications]);

// Ajouter ce useEffect pour surveiller les changements des tempScores
useEffect(() => {
  /*console.log('useEffect démarré avec:', {
    eventsCount: events?.length || 0,
    userId: user?._id,
    hasEvents: !!events,
    hasUser: !!user
  });*/

  if (!events || !user?._id) {
    console.log('Conditions non remplies pour vérifier les scores');
    return;
  }

 

  const newCanSubmitScore = {};

  events.forEach(eventData => {
    /*console.log('Analyse de l\'événement:', {
      eventId: eventData._id,
      eventTitle: eventData.title,
      teamsCount: eventData.teams?.length || 0,
      tempScoresCount: eventData.tempScores?.length || 0
    });*/

    // Vérifier si l'utilisateur est capitaine
    const isCapitain = eventData.teams?.some(team => 
      team.players?.some(player => {
        const playerId = player.user._id || player.user;
        const isCaptain = player.isCapitain && 
          (playerId === user._id || playerId.toString() === user._id.toString());

        /*console.log('Vérification joueur:', {
          playerId,
          userId: user._id,
          isCapitain: player.isCapitain,
          isCaptain,
          playerName: player.user.nom
        });*/

        return isCaptain;
      })
    );

    // Vérifier si un score a déjà été soumis
    const hasSubmittedScore = eventData.tempScores?.some(score => {
      const isSubmitter = score.submittedBy === user._id || 
        score.submittedBy?._id === user._id;

      /*console.log('Vérification score soumis:', {
        scoreId: score._id,
        submittedBy: score.submittedBy,
        userId: user._id,
        isSubmitter,
        scoreStatus: score.status,
        scoreDetails: {
          team1: score.score.team1,
          team2: score.score.team2
        }
      });*/

      return isSubmitter;
    });

    /*console.log('Résultat vérification événement:', {
      eventId: eventData._id,
      eventTitle: eventData.title,
      isCapitain,
      hasSubmittedScore,
      tempScores: eventData.tempScores,
      canSubmitScore: isCapitain && !hasSubmittedScore
    });*/

    newCanSubmitScore[eventData._id] = isCapitain && !hasSubmittedScore;
  });

  /*console.log('État final canSubmitScore:', {
    newCanSubmitScore,
    eventsProcessed: Object.keys(newCanSubmitScore).length
  });*/

  setCanSubmitScore(newCanSubmitScore);
}, [events, user?._id]);


// Dans la fonction qui ouvre le modal pour l'équipe bleue
const handleBlueTeamSelection = () => {
  setIsBlueTeam(true);
  setShowTeamCompositionModal(true);
  setSelectedTeamIndex(1);
};

// Dans la fonction qui ouvre le modal pour l'équipe noire
const handleBlackTeamSelection = () => {
  setIsBlueTeam(false);
  setShowTeamCompositionModal(true);
  setSelectedTeamIndex(0);
};

  
const fetchPastEvents = async () => {
    try {
        const response = await axios.get('http://localhost:5000/api/events');
        
        // Récupérer les événements avec le nombre d'inscrits
        const eventsWithSubscriptions = await Promise.all(
            response.data.map(async (event) => {
                const subscriptionCount = await axios.get(`http://localhost:5000/api/event-subscriptions/count/${event._id}`);
                return {
                    ...event,
                    subscribersCount: subscriptionCount.data.count,
                    sportName: event.sport.name
                };
            })
        );

        // Filtrer les événements dont le status est "Termniné"
        const pastEvents = eventsWithSubscriptions
            .filter(event => event.status === 'Terminé');

        //console.log("Tous les événements:", eventsWithSubscriptions);
        //console.log("Statuts disponibles:", [...new Set(eventsWithSubscriptions.map(e => e.status))]);
        //console.log("Événements filtrés:", pastEvents);

        setPastEvents(pastEvents);

    } catch (err) {
        console.error("Erreur de récupération :", err);
        setErrorMessage('Erreur lors de la récupération des événements passés');
    }
};

 
 const fetchUserSubscriptions = async () => {
    setIsLoading(true);
    try {
        console.log('Fetching subscriptions for user:', user._id);
        const response = await axios.get(`http://localhost:5000/api/subscriptions/user/${user._id}`);
        console.log('Subscriptions received:', response.data);
        setSubscriptions(response.data);
    } catch (error) {
        console.error('Erreur lors de la récupération des souscriptions:', error);
        setErrorMessage('Erreur lors de la récupération des souscriptions');
    } finally {
        setIsLoading(false);
    }
};

 const fetchUpcomingEvents = async () => {
  setIsLoading(true);
  try {
    const activeSubscriptions = subscriptions.filter(sub => sub.status === 'Active');
    const activeSports = activeSubscriptions.map(sub => sub.formula.sport.name);

    if (activeSports.length === 0) {
      console.log('No active sports found');
      setEvents([]);
      return;
    }

    const response = await axios.get('http://localhost:5000/api/events/by-sport', {
      params: { sports: activeSports }
    });

    const eventsWithDetails = await Promise.all(
      response.data.map(async (event) => {
        // Récupérer les détails complets de l'événement
        const eventDetails = await axios.get(`http://localhost:5000/api/events/${event._id}`);
        
        // Récupérer le nombre d'inscrits
        const subscriptionCount = await axios.get(
          `http://localhost:5000/api/event-subscriptions/count/${event._id}`
        );

        // Récupérer les tempScores
        const tempScores = await axios.get(
          `http://localhost:5000/api/events/${event._id}/temp-scores`
        );

        // Vérifier si l'utilisateur actuel a soumis un score
        const hasSubmittedScore = tempScores.data.some(
          score => score.submittedBy === user._id
        );

        return {
          ...eventDetails.data,
          subscribersCount: subscriptionCount.data.count,
          tempScores: tempScores.data,
          hasSubmittedScore,
          tempScoresLength: tempScores.data.length
        };
      })
    );

    // Filtrer les événements valides
    const validEvents = eventsWithDetails.filter(event => 
      event && 
      event.sport && 
      event.sport.name && 
      event._id && 
      event.title &&
      (event.status === 'À venir' || event.status === 'En cours')
    );

    /*console.log("Événements avec tempScores:", validEvents.map(e => ({
      id: e._id,
      tempScores: e.tempScores,
      hasSubmittedScore: e.hasSubmittedScore,
      tempScoresLength: e.tempScoresLength
    })));*/

    setEvents(validEvents);
  } catch (error) {
    console.error('Erreur lors de la récupération des événements:', error);
    setErrorMessage('Erreur lors de la récupération des événements');
  } finally {
    setIsLoading(false);
  }
};

  const checkSubscriptionStatus = async (eventId) => {
    try {
      const status = await isUserSubscribedToEvent(eventId);
      setEventSubscriptions(prev => ({
        ...prev,
        [eventId]: status
      }));
    } catch (error) {
      console.error('Erreur lors de la vérification du statut:', error);
    }
  };

  const handleEventRegistration = async (eventId) => {
    try {
      const response = await axios.post('http://localhost:5000/api/event-subscriptions', {
        user: user._id,
        event: eventId,
        status: 'Validé',
      });

      if (response.data && response.data.message) {
        setSuccessMessage(`Inscription réussie à l'événement.`);
        await fetchUserSubscriptions();
        await fetchUpcomingEvents();
        await checkSubscriptionStatus(eventId);
      }
    } catch (error) {
      console.error('Erreur lors de l\'inscription :', error);
      setErrorMessage('Erreur lors de l\'inscription à l\'événement.');
    }
  };

  const handleEventUnsubscription = async (eventId) => {
    try {
      const response = await axios.delete(`http://localhost:5000/api/event-subscriptions/${user._id}/${eventId}`);
      if (response.status === 200) {
        setSuccessMessage('Vous vous êtes désinscrit de l\'événement avec succès.');
        await fetchUserSubscriptions();
        await fetchUpcomingEvents();
        await checkSubscriptionStatus(eventId);
      }
    } catch (error) {
      setErrorMessage('Erreur lors de la désinscription de l\'événement.');
    }
  };

  const isUserSubscribedToEvent = async (eventId) => {
    if (!eventId || !user?._id) return false;
    
    try {
      const response = await axios.get(`http://localhost:5000/api/event-subscriptions/check`, {
        params: {
          userId: user._id,
          eventId: eventId
        }
      });
      return response.data.isSubscribed;
    } catch (error) {
      console.error('Erreur lors de la vérification de l\'inscription:', error);
      return false;
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/auth');
  };

  const getStatusClassAndIcon = (status) => {
    switch (status) {
      case 'Active':
        return { className: 'status-actif', icon: 'mdi-check-circle' };
      case 'En attente':
        return { className: 'status-en-attente', icon: 'mdi-clock-outline' };
      case 'Expirée':
        return { className: 'status-expiree', icon: 'mdi-alert-circle' };
      default:
        return { className: '', icon: 'mdi-help-circle' };
    }
  };

const fetchEventSubscribers = async (eventId, eventTitle) => {
  try {
    setIsLoading(true);
    setErrorMessage('');

    const eventResponse = await axios.get(`http://localhost:5000/api/events/${eventId}`);
    const event = eventResponse.data;

    const subscribersResponse = await axios.get(
      `http://localhost:5000/api/event-subscriptions/${eventId}`
    );

    const countResponse = await axios.get(
      `http://localhost:5000/api/event-subscriptions/count/${eventId}`
    );

    // Traitement des subscribers
    const subscribers = event.subscribers?.map(subscriber => ({
      userId: subscriber.user._id,
      nom: subscriber.user.nom,
      prenom: subscriber.user.prenom || '',
      elo: subscriber.user.elo || 1024,
      present: subscriber.present,
      status: subscriber.status
    })) || [];

    console.log('Liste des subscribers:', subscribers);

    if (event.teams && event.teams.length > 0) {
      const teamsWithElo = event.teams.map((team, index) => {
        const playersWithElo = team.players.map(player => ({
          nom: player.user.nom,
          prenom: player.user.prenom || '',
          elo: player.elo,
          isCapitain: player.isCapitain,
          present: player.present,
          userId: player.user._id
        }));

        return {
          teamId: team._id,
          teamColor: index === 0 ? 'black' : 'blue',
          teamElo: team.teamElo,
          score: team.score || 0,
          status: team.status,
          players: playersWithElo
        };
      });

      console.log('Équipes traitées avec ELO:', teamsWithElo);
      
      setSelectedEventDetails({
        ...event,
        teams: teamsWithElo,
        subscribersCount: countResponse.data.count,
        subscribers: subscribersResponse.data // Ajout des subscribers dans les détails
      });
    }

    setSelectedEventSubscribers(subscribersResponse.data); // Mise à jour du state des subscribers
    setSelectedEventTitle(eventTitle);
    setShowSubscribersModal(true);

  } catch (error) {
    console.error('Erreur lors de la récupération des équipes:', error);
    setErrorMessage('Erreur lors de la récupération des détails des équipes');
  } finally {
    setIsLoading(false);
  }
};

const fetchNotifications = async () => {
  try {
    const response = await axios.get(`http://localhost:5000/api/notifications/${user._id}`);

    console.log('Nouvelles notifications reçues 1:', response.data);
    
    // Trier les notifications par date
    const sortedNotifications = response.data.sort((a, b) => 
      new Date(b.createdAt) - new Date(a.createdAt)
    );

    // Vérifier s'il y a des nouvelles notifications non lues qui nécessitent un rafraîchissement
    const hasUnreadNotifications = sortedNotifications.some(notif => 
      !notif.read && (notif.type === 'SCORE_DISPUTE' || notif.type === 'SCORE_VALIDATED')
    );

    // Ne rafraîchir qu'une seule fois si nécessaire
    if (hasUnreadNotifications) {
      await Promise.all([
        fetchUpcomingEvents(),
        fetchPastEvents()
      ]);
    }

    setNotifications(sortedNotifications);

  } catch (error) {
    console.error('Erreur lors de la récupération des notifications:', error);
    setErrorMessage('Erreur lors de la récupération des notifications');
  }
};

const markNotificationAsRead = async (notificationId, type) => {
  try {
    if (type === 'SCORE_VALIDATED') {
      if (!notifications.find(n => n._id === notificationId).read) {
        // Si la notification n'est pas lue, la marquer comme lue
        await axios.patch(`http://localhost:5000/api/notifications/${notificationId}/read`);
        setNotifications(prevNotifications =>
          prevNotifications.map(notif =>
            notif._id === notificationId ? { ...notif, read: true } : notif
          )
        );
      } else {
        // Si la notification est déjà lue, la supprimer
        const notification = notifications.find(n => n._id === notificationId);
        await axios.delete(`http://localhost:5000/api/notifications/delete`, {
          data: {
            eventId: notification.eventId,
            userId: user._id,
            type: notification.type
          }
        });
        
        // Mettre à jour l'état local après la suppression
        setNotifications(prevNotifications =>
          prevNotifications.filter(notif => notif._id !== notificationId)
        );
      }
    } else {
      // Pour les autres types, comportement inchangé
      await axios.patch(`http://localhost:5000/api/notifications/${notificationId}/read`);
      setNotifications(prevNotifications =>
        prevNotifications.map(notif =>
          notif._id === notificationId ? { ...notif, read: true } : notif
        )
      );
    }
  } catch (error) {
    console.error('Erreur lors du traitement de la notification:', error);
    setErrorMessage('Erreur lors du traitement de la notification');
  }
};
// Ajout de la fonction de validation
const validateTeamIndex = (index) => {
  return index === 0 || index === 1;
};

// Modification de la fonction handleShowTeamComposition
const handleShowTeamComposition = (eventId, teamIndex) => {
  console.log('Ouverture modal composition:', {
    eventId,
    teamIndex,
    equipe: teamIndex === 0 ? 'noire' : 'bleue',
    currentUser: user._id
  });

  // Vérification de l'événement avant ouverture du modal
  axios.get(`http://localhost:5000/api/events/${eventId}`)
    .then(response => {
      const event = response.data;
      const team = event.teams[teamIndex];
      
      // Vérifier si l'utilisateur est capitaine de cette équipe
      const isUserCaptain = team?.players?.some(player => {
        console.log('Vérification joueur:', {
          player,
          userId: user._id,
          isCapitain: player.isCapitain,
          playerUserId: player.user._id || player.user
        });
        
        return player.isCapitain && 
          (player.user._id === user._id || player.user === user._id);
      });

      console.log('Résultat vérification capitaine:', {
        isUserCaptain,
        teamIndex,
        teamPlayers: team?.players
      });

      if (!isUserCaptain) {
        setErrorMessage('Vous n\'êtes pas capitaine de cette équipe');
        return;
      }

      setSelectedEventId(eventId);
      setSelectedTeamIndex(teamIndex);
      setShowTeamCompositionModal(true);
    })
    .catch(error => {
      console.error('Erreur lors de la vérification de l\'événement:', error);
      setErrorMessage('Erreur lors de la vérification des droits de capitaine');
    });
};

const refreshNotifications = async () => {
  try {
    //console.log('Rafraîchissement des notifications...');
    const response = await axios.get(`http://localhost:5000/api/notifications/${user._id}`);
    
    if (response.data) {
     // console.log('Nouvelles notifications reçues:', response.data);
      // Trier les notifications par date de création (les plus récentes en premier)
      const sortedNotifications = response.data.sort((a, b) => 
        new Date(b.createdAt) - new Date(a.createdAt)
      );
      setNotifications(sortedNotifications);
    }
  } catch (error) {
    console.error('Erreur lors du rafraîchissement des notifications:', error);
  }
};

const handleAddScore = async (eventId) => {
  try {
    console.log('handleAddScore appelé avec eventId:', eventId);
    
    // Récupérer les détails de l'événement
    const eventResponse = await axios.get(`http://localhost:5000/api/events/${eventId}`);
    const event = eventResponse.data;
    
    // Vérification améliorée du capitaine
    const isUserCaptain = event.teams?.some(team => 
      team.players?.some(player => {
        const playerId = player.user._id || player.user;
        return player.isCapitain && 
          (playerId === user._id || playerId.toString() === user._id.toString());
      })
    );

    if (!isUserCaptain) {
      setErrorMessage('Vous devez être capitaine pour ajouter le score');
      return;
    }

    // Vérifier si les équipes sont configurées
    if (!event.teamConfiguration?.isConfigured) {
      setErrorMessage('Les équipes doivent être complètement configurées avant d\'ajouter un score');
      return;
    }

    // Définir l'événement sélectionné et ouvrir le modal
    setSelectedEventDetails(event);
    setShowScoreModal(true);
    console.log('Modal score ouvert pour l\'événement:', event.title);

  } catch (error) {
    console.error('Erreur lors de l\'ouverture du modal de score:', error);
    setErrorMessage('Erreur lors de l\'ouverture du modal de score');
  }
};

// Ajouter cette fonction pour gérer la soumission du score
/*const handleScoreSubmit = async (updatedEvent) => {
  try {
    console.log('Données événement reçues:', updatedEvent);
    
    // Vérification du capitaine actuel
    const teamIndex = updatedEvent.teams.findIndex(team => 
      team.players.some(player => {
        const playerId = typeof player.user === 'object' ? 
          player.user._id.toString() : 
          player.user.toString();
        const userId = typeof user._id === 'object' ? 
          user._id.toString() : 
          user._id;

        return player.isCapitain && playerId === userId;
      })
    );

    if (teamIndex === -1) {
      throw new Error('Vous n\'êtes pas capitaine d\'une équipe');
    }

    const scoreResponse = await axios.post(`http://localhost:5000/api/events/${updatedEvent._id}/temp-scores`, {
      scores: {
        team1: parseInt(updatedEvent.teams[0].score),
        team2: parseInt(updatedEvent.teams[1].score)
      },
      teamIndex,
      userId: user._id
    });

    console.log('Réponse soumission score:', scoreResponse.data);
    
     // Mettre à jour l'événement dans la liste des événements à venir
     setUpcomingEvents(prevEvents => 
      prevEvents.map(event => {
        if (event._id === updatedEvent._id) {
          return {
            ...event,
            tempScores: [
              ...(event.tempScores || []),
              {
                submittedBy: user._id,
                status: scoreResponse.data.status
              }
            ]
          };
        }
        return event;
      })
    );
    
    // Fermer le modal
    setShowScoreModal(false);

    // Supprimer les notifications actuelles pour ce capitaine
    const notificationsToDelete = [
      'ADD_SCORE', 
      'SCORE_REMINDER', 
      'TEAM_COMPLETE'
    ];

    // Supprimer les notifications pour le capitaine actuel
    for (const type of notificationsToDelete) {
      try {
        await axios.delete(`http://localhost:5000/api/notifications/delete`, {
          data: {
            eventId: updatedEvent._id,
            userId: user._id,
            type: type
          }
        });
        console.log(`Notification ${type} supprimée avec succès`);
      } catch (err) {
        console.error(`Erreur suppression notification ${type}:`, err);
      }
    }

    // Rafraîchir les données
    await Promise.all([
      fetchUpcomingEvents(),
      fetchPastEvents(),
      refreshNotifications()
    ]);

    return scoreResponse.data.status;

  } catch (error) {
    console.error('Erreur lors de la soumission:', error);
    setErrorMessage(error.message || 'Erreur lors de la soumission du score');
    throw error;
  }
};*/

const handleScoreSubmit = async (updatedEvent) => {
  try {
    console.log('=== DÉBUT SOUMISSION DES SCORES ===');
    console.log('Événement complet:', updatedEvent);
    console.log('User ID:', user._id);
    
    // Vérification du capitaine
    const teamIndex = updatedEvent.teams.findIndex(team => 
      team.players.some(player => {
        const playerId = typeof player.user === 'object' ? 
          player.user._id.toString() : 
          player.user.toString();
        const userId = typeof user._id === 'object' ? 
          user._id.toString() : 
          user._id;

        console.log('Vérification capitaine:', {
          playerId,
          userId,
          isCapitain: player.isCapitain
        });

        return player.isCapitain && playerId === userId;
      })
    );

    console.log('Index équipe du capitaine:', teamIndex);

    if (teamIndex === -1) {
      console.log('❌ Utilisateur non capitaine');
      throw new Error('Vous n\'êtes pas capitaine d\'une équipe');
    }

    const scoreData = {
      scores: {
        team1: parseInt(updatedEvent.teams[0].score),
        team2: parseInt(updatedEvent.teams[1].score)
      },
      teamIndex,
      userId: user._id
    };

    console.log('Données à envoyer:', scoreData);

    // Soumission des scores
    const scoreResponse = await axios.post(
      `http://localhost:5000/api/events/${updatedEvent._id}/temp-scores`, 
      scoreData
    );

    console.log('Réponse API scores:', scoreResponse.data);

    // Si les deux capitaines ont validé le score
    if (scoreResponse.data.status === 'VALIDATED') {
  console.log('=== DÉBUT MISE À JOUR DES STATS ===');
  console.log('Event complet:', updatedEvent);
  
  for (const [index, team] of updatedEvent.teams.entries()) {
    console.log(`\n--- TRAITEMENT ÉQUIPE ${index + 1} ---`);
    console.log('Équipe complète:', team);
    
    const opposingTeam = updatedEvent.teams[index === 0 ? 1 : 0];
    console.log('Équipe adverse:', opposingTeam);
    
    const isWinner = team.score > opposingTeam.score;
    const isDraw = team.score === opposingTeam.score;

    console.log('Analyse du résultat:', {
      équipe: index + 1,
      score: team.score,
      scoreAdverse: opposingTeam.score,
      victoire: isWinner,
      nul: isDraw,
      défaite: !isWinner && !isDraw
    });

    for (const player of team.players) {
      console.log('\n=== TRAITEMENT JOUEUR ===');
      console.log('Données joueur:', player);
      
      const playerId = typeof player.user === 'object' ? 
        player.user._id : 
        player.user;

      console.log('Préparation mise à jour:', {
        playerId,
        nomJoueur: player.user.nom || 'Non défini',
        estCapitaine: player.isCapitain,
        résultat: isWinner ? 'win' : isDraw ? 'draw' : 'loss'
      });

      try {
        console.log('Envoi données à l\'API:', {
          endpoint: `http://localhost:5000/api/update`,
          données: {
            userId: playerId,
            gameResult: isWinner ? 'win' : isDraw ? 'draw' : 'loss',
            goalsFor: team.score,
            goalsAgainst: opposingTeam.score,
            opposingElo: opposingTeam.averageElo || 1000,
            isTournamentMatch: updatedEvent.type === 'tournament'
          }
        });

        const statsResponse = await axios.post(`http://localhost:5000/api/update`, {
          userId: playerId,
          gameResult: isWinner ? 'win' : isDraw ? 'draw' : 'loss',
          goalsFor: team.score,
          goalsAgainst: opposingTeam.score,
          opposingElo: opposingTeam.averageElo || 1000,
          isTournamentMatch: updatedEvent.type === 'tournament'
        });

        console.log('Réponse API:', {
          status: statsResponse.status,
          données: statsResponse.data
        });

        console.log('Mise à jour réussie pour le joueur:', {
          playerId,
          gameResult: isWinner ? 'win' : isDraw ? 'draw' : 'loss',
          goalsFor: team.score,
          goalsAgainst: opposingTeam.score,
          eloMisAJour: statsResponse.data.newElo || 'Non disponible'
        });

      } catch (error) {
        console.error('❌ ERREUR mise à jour stats joueur:', {
          playerId,
          error: error.message,
          response: error.response?.data,
          stack: error.stack
        });
      }
    }
  }
  
  console.log('=== FIN MISE À JOUR DES STATS ===');
} else {
  console.log('Scores non validés:', scoreResponse.data);
}

    console.log('=== NETTOYAGE DES NOTIFICATIONS ===');
    const notificationsToDelete = ['ADD_SCORE', 'SCORE_REMINDER', 'TEAM_COMPLETE'];
    
    for (const type of notificationsToDelete) {
      try {
        await axios.delete(`http://localhost:5000/api/notifications/delete`, {
          data: {
            eventId: updatedEvent._id,
            userId: user._id,
            type: type
          }
        });
        console.log(`✅ Notification ${type} supprimée`);
      } catch (err) {
        console.error(`❌ Erreur suppression notification ${type}:`, err);
      }
    }

    console.log('=== RAFRAÎCHISSEMENT DES DONNÉES ===');
    await Promise.all([
      fetchUpcomingEvents(),
      fetchPastEvents(),
      refreshNotifications()
    ]);

    console.log('=== FIN DE LA SOUMISSION ===');
    return scoreResponse.data.status;

  } catch (error) {
    console.error('❌ ERREUR CRITIQUE:', error);
    console.error('Message:', error.message);
    console.error('Stack:', error.stack);
    if (error.response) {
      console.error('Réponse serveur:', error.response.data);
    }
    setErrorMessage(error.message || 'Erreur lors de la soumission du score');
    throw error;
  }
};

// Mémoriser les événements filtrés pour éviter les re-rendus inutiles
const filteredEvents = useMemo(() => {
  return events.map(event => ({
      ...event,
      hasDispute: event.tempScores?.some(score => score.status === 'DISPUTED')
  }));
}, [events]);


  return (
    <div className="dashboard">
       <UserSidebar
                onShowDashboard={handleShowDashboard}
                onShowSubscriptions={handleShowSubscriptions}
                onShowFormulas={handleShowFormulas}
                onShowEvents={handleShowEvents}
                onShowProfil={handleShowProfil}
            />

      <main className="main-content">
        {isLoading ? (
          <div className="loading">Chargement...</div>
        ) : (
          <>
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}

            {activeView === 'user-dashboard' && (
              <>
                <div className="stats-grid">
                    {/* Retirez la classe stats-grid et ajoutez une nouvelle structure */}
                    <div className="dashboard-content">
                      {/* Profil utilisateur */}
                      <div className="user-profile-section">
                        <div className="user-profile">
                          <div className="user-avatar">
                            <i className="mdi mdi-account"></i>
                          </div>
                          <div className="user-info">
                            <div className="user-name">{user?.nom || 'Utilisateur'}</div>
                            <div className="user-email">{user?.email || 'Email non disponible'}</div>
                          </div>
                        </div>
                      </div>

                      {/* Ajoutez cette section pour les notifications */}
                      {notifications.length > 0 && (
                        <div className="notifications-container">
                          <div className="card">
                            <div className="card-header">
                              <h2 className="card-title">
                                <i className="mdi mdi-bell"></i> Notifications
                              </h2>
                            </div>
                            <ul className="notifications-list">
                              {notifications.map(notification => {
                                const notificationClass = (() => {
                                  switch (notification.type) {
                                    case 'SCORE_WAITING':
                                      return 'score-waiting';
                                    case 'SCORE_SUBMITTED':
                                      return 'score-submitted';
                                    case 'SCORE_VALIDATED':
                                      return 'score-validated';
                                    case 'SCORE_DISPUTE':
                                      return 'score-disputed';
                                    default:
                                      return '';
                                  }
                                })();

                                return (
                                  <li 
                                    key={notification._id} 
                                    className={`notification-item ${!notification.read ? 'unread' : ''} ${notificationClass}`}
                                    data-type={notification.type}
                                  >
                                    <div className="notification-content">
                                      <p>{notification.message}</p>
                                      <small>{new Date(notification.createdAt).toLocaleDateString()}</small>
                                      {notification.type === 'CAPTAIN_SELECTED' && 
                                        notification.status !== 'COMPLETED' && (
                                          <button 
                                            className="notification-action-btn"
                                            onClick={() => {
                                              const teamIndex = parseInt(notification.teamIndex);
                                              console.log('Index équipe sélectionné:', {
                                                teamIndex,
                                                type: typeof teamIndex,
                                                equipe: teamIndex === 0 ? 'noire' : 'bleue',
                                                userId: user._id,
                                                notificationId: notification._id
                                              });
                                          
                                              if (validateTeamIndex(teamIndex)) {
                                                handleShowTeamComposition(notification.eventId, teamIndex);
                                              } else {
                                                setErrorMessage('Index d\'équipe invalide dans la notification');
                                              }
                                            }}
                                          >
                                            Composer {parseInt(notification.teamIndex) === 0 ? 'l\'équipe noire' : 'l\'équipe bleue'}
                                          </button>
                                      )}

                                      {notification.type === 'ADD_SCORE' && 
                                      notification.status === 'PENDING' && (
                                        <button 
                                          className="notification-action-btn score-btn"
                                          onClick={() => handleAddScore(notification.eventId)}
                                        >
                                          Ajouter le score
                                        </button>
                                      )}
                                    </div>
                                    {!notification.read ? (
                                      // Bouton "marquer comme lu" pour les notifications non lues
                                      <button 
                                        className="mark-read-btn"
                                        onClick={() => markNotificationAsRead(notification._id, notification.type)}
                                        title="Marquer comme lu"
                                      >
                                        <i className="mdi mdi-check"></i>
                                      </button>
                                    ) : (
                                      // Bouton "supprimer" uniquement pour les notifications SCORE_VALIDATED lues
                                      notification.type === 'SCORE_VALIDATED' && (
                                        <button 
                                          className="delete-btn"
                                          onClick={() => markNotificationAsRead(notification._id, notification.type)}
                                          title="Supprimer"
                                        >
                                          <i className="mdi mdi-delete"></i>
                                        </button>
                                      )
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  <SubscriptionCard 
                    subscriptions={subscriptions}
                    formatDate={formatDate}
                    getStatusClassAndIcon={getStatusClassAndIcon}
                  />

                  {/* Stats Card */}
                  <StatsCard user={user} />

                </div>


                <div className="card">
                    <div className="card-top">
                      <h2>
                        <i className="mdi mdi-calendar"></i>
                        Événements
                      </h2>
                      <a href="/events" className="card-link">Voir tous les événements</a>
                    </div>
                    <div className="card-header">
                      <h2 className="card-title">Événements à venir</h2>
                    </div>
                
                  <ul className="event-list">
                    {events.map(event => {
                      if (!event || !event.sport || !event.sport.name || !event._id || !event.title) {
                        console.warn('Event with missing required properties:', event);
                        return null;
                      }

                      const sportImage = sportImages[event.sport.name];
                      
                      return (
                        <li key={event._id} className="event-card">
                          {sportImage && <img src={sportImage} alt={event.sport.name} className="event-image" />}
                          <div className="event-header">
                            <h3 className="event-title">{event.title}</h3>
                            <span className="event-sport-tag">{event.sport.name}</span>
                          </div>
                          <div className="event-info">
                            <div className="event-info-item">
                              <i className="mdi mdi-calendar"></i>
                              <span>{event.date ? formatDate(event.date) : 'Date non définie'}</span>
                            </div>
                            <div className="event-info-item">
                              <i className="mdi mdi-map-marker"></i>
                              <span>{event.location || 'Lieu non défini'}</span>
                            </div>
                            <div className="event-info-item">
                              <i className="mdi mdi-account-group"></i>
                              <span>{event.subscribersCount || 0} inscrits</span>
                              {event.subscribersCount > 0 && (
                                <button 
                                className="btn btn-info btn-sm"
                                onClick={() => fetchEventSubscribers(event._id, event.title)}
                              >
                                <i className="mdi mdi-information-outline"></i> Détails
                              </button>
                              )}
                            </div>
                          </div>
                          <p className="event-description">{event.description || 'Aucune description disponible'}</p>
                        
                        {eventSubscriptions[event._id] ? (
                          <div className="event-buttons">
                            {event.teamConfiguration?.isConfigured ? (
                              <>
                                {(() => {
                                  // Vérifier si l'utilisateur est capitaine et peut soumettre un score
                                  const canAddScore = canSubmitScore[event._id];
                                  // Vérifier si un score a déjà été soumis
                                  const hasSubmittedScore = event.tempScores?.some(score => 
                                    score.submittedBy === user._id || 
                                    score.submittedBy?._id === user._id
                                  );

                                  /*console.log('État des boutons:', {
                                    eventId: event._id,
                                    canAddScore,
                                    hasSubmittedScore,
                                    tempScores: event.tempScores
                                  });*/

                                  return (
                                    <>
                                      {canAddScore && (
                                        <button 
                                          className="score-btn"
                                          onClick={() => {
                                            console.log('Clic sur Ajouter score:', {
                                              eventId: event._id,
                                              userId: user._id,
                                              canSubmitScore: canSubmitScore[event._id]
                                            });
                                            handleAddScore(event._id);
                                          }}
                                        >
                                          Ajouter score
                                        </button>
                                      )}

                                      {/* N'afficher le bouton Se désinscrire que si aucun score n'a été soumis */}
                                      {!hasSubmittedScore && !canAddScore && (
                                        <button 
                                          className="unregister-btn"
                                          onClick={() => handleEventUnsubscription(event._id)}
                                        >
                                          Se désinscrire
                                        </button>
                                      )}

                                      {/* Le badge de litige reste toujours visible si présent */}
                                      {/*{event.tempScores?.some(score => score.status === 'DISPUTED') && (
                                        <span className="dispute-badge">
                                          <i className="mdi mdi-alert"></i> Litige en cours
                                        </span>
                                      )}*/}
                                      {/* Le badge de litige s'affiche uniquement si une notification de litige existe */}
                                      {notifications.some(notif => 
                                        notif.type === 'SCORE_DISPUTE' && 
                                        notif.eventId === event._id
                                      ) && (
                                        <span className="dispute-badge">
                                          <i className="mdi mdi-alert"></i> Litige en cours
                                        </span>
                                      )}


                                    </>
                                  );
                                })()}
                              </>
                            ) : (
                              <>
                                <button className="register-btn disabled" disabled>
                                  Déjà inscrit
                                </button>
                                <button 
                                  className="unregister-btn"
                                  onClick={() => handleEventUnsubscription(event._id)}
                                >
                                  Se désinscrire
                                </button>
                              </>
                            )}
                          </div>
                          ) : (
                            <button 
                              className="register-btn" 
                              onClick={() => handleEventRegistration(event._id)}
                            >
                              S'inscrire
                            </button>
                          )}


                        </li>
                      );
                    })}
                  </ul>
              
                  <div className="card-header">
                    <h2 className="card-title">Événements passés</h2>
                  </div>
                  <ul className="event-list">
                    {pastEvents.map(event => {
                      if (!event || !event.sport || !event.sport.name || !event._id || !event.title) {
                        return null;
                      }

                      const sportImage = sportImages[event.sport.name];
                      
                      return (
                        <li key={event._id} className="event-card past-event">
                          {sportImage && <img src={sportImage} alt={event.sport.name} className="event-image" />}
                          <div className="event-header">
                            <h3 className="event-title">{event.title}</h3>
                            <span className="event-sport-tag">{event.sport.name}</span>
                          </div>
                          <div className="event-info">
                            <div className="event-info-item">
                              <i className="mdi mdi-calendar"></i>
                              <span>{event.date ? formatDate(event.date) : 'Date non définie'}</span>
                            </div>
                            <div className="event-info-item">
                              <i className="mdi mdi-map-marker"></i>
                              <span>{event.location || 'Lieu non défini'}</span>
                            </div>
                          </div>
                          
                          <div className="teams-info">
                            <div className="match-header">
                              <h4>Résultat du match</h4>
                              {event.teams && (
                                <>
                                  {/* Badge de résultat */}
                                  {(() => {
                                    const userTeamIndex = event.teams.findIndex(team =>
                                      team.players.some(player => {
                                        const playerId = typeof player.user === 'object' ? player.user._id : player.user;
                                        return playerId === user._id;
                                      })
                                    );

                                    if (userTeamIndex !== -1 && event.teams[0].score !== undefined && event.teams[1].score !== undefined) {
                                      const userTeam = event.teams[userTeamIndex];
                                      const opposingTeam = event.teams[userTeamIndex === 0 ? 1 : 0];

                                      if (userTeam.score > opposingTeam.score) {
                                        return <span className="result-badge win">WIN</span>;
                                      } else if (userTeam.score < opposingTeam.score) {
                                        return <span className="result-badge loss">LOSS</span>;
                                      }
                                    }
                                    return null;
                                  })()}
                                </>
                              )}
                            </div>

                            {/* Affichage des équipes */}
                            {event.teams && event.teams.map((team, index) => (
                              <div key={index} className={`team-details team-${index === 0 ? 'black' : 'blue'}`}>
                                <h5>Équipe {index === 0 ? 'Noire' : 'Bleue'}</h5>
                                <div className="score-status">
                                  {team.tempScore !== undefined ? (
                                    <>
                                      <p className="temp-score">Score proposé: {team.tempScore}</p>
                                      <span className="score-status-badge pending">
                                        En attente de validation
                                      </span>
                                    </>
                                  ) : team.score !== undefined ? (
                                    <>
                                      <p className="final-score">Buts: {team.score}</p>
                                      <span className="score-status-badge validated">
                                        Score validé
                                      </span>
                                    </>
                                  ) : (
                                    <p className="no-score">Score non soumis</p>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                          
                          <button 
                            className="btn btn-info btn-sm"
                            onClick={() => fetchEventSubscribers(event._id, event.title)}
                          >
                            <i className="mdi mdi-information-outline"></i> Voir les détails
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            )}

                  {activeView === 'formulas' && (
                    <div className="formulas-view">
                      <FormulasList refreshNotifications={refreshNotifications} />
                    </div>
                  )}

                  {activeView === 'events' && (
                    <EventsList 
                      refreshNotifications={refreshNotifications}
                    />
                  )}

                  {activeView === 'profil' && (
                    <UserProfile />
                  )}
                </>
              )}
      </main>

       {/* Modal pour les inscrits et les détails */}
       {showSubscribersModal && (
          <div className="modal-overlay">
            <div className="modal-content2">
              <div className="modal-header">
                <h3>{selectedEventDetails?.title}</h3>
                <button 
                  className="close-modal-btn"
                  onClick={() => {
                    setShowSubscribersModal(false);
                    setSelectedEventSubscribers([]);
                    setSelectedEventDetails(null);
                  }}
                >
                  <i className="mdi mdi-close"></i>
                </button>
              </div>

              <div className="event-details">
                  <div className="event-info">
                    <p><i className="mdi mdi-calendar"></i> {new Date(selectedEventDetails?.date).toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</p>
                    <p><i className="mdi mdi-map-marker"></i> {selectedEventDetails?.location}</p>
                    <p><i className="mdi mdi-account-group"></i> {selectedEventDetails?.subscribersCount} {/*/ {selectedEventDetails?.maxParticipants} */} participants</p>
                  </div>

                  <div className="event-description">
                    <h4>Description</h4>
                    <p>{selectedEventDetails?.description}</p>
                  </div>

                 <div className="subscribers-list">
                    <h4>Liste des inscrits</h4>
                    {selectedEventSubscribers && selectedEventSubscribers.length > 0 ? (
                      <div className="subscribers-columns">
                        {/* Première colonne */}
                        <ul>
                          {selectedEventSubscribers
                            .slice(0, selectedEventSubscribers.length <= 10 ? 5 : 10)
                            .map((subscriber, index) => (
                              <li key={index} className="subscriber-item">
                                <span className="player-number">{index + 1}</span>
                                <span>{subscriber.user.nom} {subscriber.user.prenom}</span>
                              </li>
                            ))}
                        </ul>

                        {/* Deuxième colonne */}
                        <ul>
                          {selectedEventSubscribers
                            .slice(selectedEventSubscribers.length <= 10 ? 5 : 10)
                            .map((subscriber, index) => (
                              <li key={index + (selectedEventSubscribers.length <= 10 ? 5 : 10)} className="subscriber-item">
                                <span className="player-number">
                                  {index + 1 + (selectedEventSubscribers.length <= 10 ? 5 : 10)}
                                </span>
                                <span>{subscriber.user.nom} {subscriber.user.prenom}</span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    ) : (
                      <p>Aucun inscrit pour le moment</p>
                    )}
                  </div>
                  
                  {/* Composition des équipes si configurée */}
                 {(selectedEventDetails?.teamConfiguration?.isConfigured || selectedEventDetails?.status === 'Terminé') && 
                    selectedEventDetails?.teams && (
                      <div className={`${selectedEventDetails.status === 'Terminé' ? 'teams-details' : 'teams-composition'}`}>
                        {console.log("selectedEventDetails:", {
                          isConfigured: selectedEventDetails?.teamConfiguration?.isConfigured,
                          teams: selectedEventDetails?.teams,
                          fullDetails: selectedEventDetails
                        })}
                       { <TeamCompositionWithScore teams={selectedEventDetails.teams} />}
                      </div>
                  )}


                  <div className="modal-footer">
                    <button 
                      className="btn btn-secondary"
                      onClick={() => {
                        setShowSubscribersModal(false);
                        setSelectedEventSubscribers([]);
                        setSelectedEventDetails(null);
                      }}
                    >
                      Fermer
                    </button>
                  </div>
                </div>



            </div>
          </div>


      )}

      {/* Modal de composition d'équipe */}
      <TeamCompositionModal 
        show={showTeamCompositionModal}
        eventId={selectedEventId}
        teamIndex={selectedTeamIndex}
        onClose={async () => {
          setShowTeamCompositionModal(false);
          setSelectedEventId(null);
          setSelectedTeamIndex(null);
          // Ajout des rafraîchissements
          await fetchUpcomingEvents();
          await fetchPastEvents();
          await refreshNotifications();
        }}
      />

       {/* Ajouter le ScoreModal ici */}
       <ScoreModal 
          show={showScoreModal} 
          event={selectedEventDetails} 
          user={user}
          onClose={async () => {
            try {
              console.log('ScoreModal - Fermeture du modal');
              setShowScoreModal(false);
              setSelectedEventDetails(null);

              // Vérifier si un score a été soumis
              if (selectedEventDetails?.tempScores?.some(score => 
                score.submittedBy === user._id
              )) {
                console.log('ScoreModal - Score soumis, rafraîchissement des données');
                await Promise.all([
                  fetchUpcomingEvents(),
                  fetchPastEvents(),
                  refreshNotifications()
                ]);
                console.log('ScoreModal - Rafraîchissement terminé');
              }
            } catch (error) {
              console.error('ScoreModal - Erreur lors du rafraîchissement:', error);
              setErrorMessage('Erreur lors du rafraîchissement des données');
            }
          }}
          onScoreSubmit={async (updatedEvent) => {
            try {
              await handleScoreSubmit(updatedEvent);
              console.log('ScoreModal - Score soumis avec succès');
              
              // Rafraîchir les données immédiatement après la soumission
              await Promise.all([
                fetchUpcomingEvents(),
                fetchPastEvents(),
                refreshNotifications()
              ]);
            } catch (error) {
              console.error('ScoreModal - Erreur lors de la soumission:', error);
              throw error;
            }
          }}
        />
      </div>

  );
};
export default UserMainDashboard;