import React from 'react';
import './TeamCompositionWithScore.css';

const TeamCompositionWithScore = ({ teams }) => {
  console.log("Teams reçues dans TeamCompositionWithScore:", teams);

  // Fonction utilitaire pour trier les joueurs (capitaines en premier)
  const sortPlayers = (players) => {
    return players.sort((a, b) => {
      if (a.isCapitain) return -1;
      if (b.isCapitain) return 1;
      return 0;
    });
  };

  // Fonction pour obtenir les détails d'un joueur
const getPlayerDetails = (player) => {
  console.log('=== Détails du joueur ===');
  console.log('Player reçu:', player);

  if (player.user && typeof player.user === 'object') {
    // Cas où le joueur a un objet user
    const details = {
      nom: player.user.nom,
      prenom: player.user.prenom,
      elo: player.finalElo || player.user.elo || 1000, // Utiliser finalElo s'il existe
      eloChange: player.eloChange || 0,
      finalElo: player.finalElo || (player.user.elo + (player.eloChange || 0))
    };
    console.log('Détails calculés (user):', details);
    return details;
  }

  // Cas où le joueur est direct
  const details = {
    nom: player.nom,
    prenom: player.prenom,
    elo: player.finalElo || player.elo || 1000, // Utiliser finalElo s'il existe
    eloChange: player.eloChange || 0,
    finalElo: player.finalElo || (player.elo + (player.eloChange || 0))
  };
  console.log('Détails calculés (direct):', details);
  return details;
};

  return (
    <>
      <h4>Composition des équipes</h4>
      <div className="teams-container">
        {/* Équipe Noire */}
        <div className="team black">
          <div className="team-header">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="#000000" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              className="lucide lucide-shield h-5 w-5 text-black mr-2"
            >
              <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" />
            </svg>
            <span className="font-bold">Équipe Noire</span>
            <div className="team-stats">
              {/*<span className={`team-score ${(!teams[0]?.score || teams[0].score === 0) ? 'pending-score' : ''}`}>
                {teams[0]?.score && teams[0].score !== 0 ? teams[0].score : "En attente"}
              </span>*/}
              <span className="team-elo">ELO: {teams[0]?.teamElo || 1000}</span>
            </div>
          </div>
          <div className="players-list">
            {teams[0]?.players && sortPlayers(teams[0].players).map((player, index) => {
              const playerDetails = getPlayerDetails(player);
              return (
                <div 
                  key={index} 
                  className={`player ${player.isCapitain ? 'captain' : ''}`}
                >
                  {/* Pour l'équipe noire et bleue */}
                  <div className="player-info">
                    <span>{playerDetails.nom} {playerDetails.prenom}</span>
                    <span className="player-elo">
                      ELO: {playerDetails.finalElo}
                      {playerDetails.eloChange !== 0 && (
                        <span className={`elo-change ${playerDetails.eloChange > 0 ? 'positive' : 'negative'}`}>
                          ({playerDetails.eloChange > 0 ? '+' : ''}{playerDetails.eloChange})
                        </span>
                      )}
                    </span>
                  </div>
                  {player.isCapitain && <span className="captain-badge">Capitaine</span>}
                </div>
              );
            })}
          </div>
        </div>


        {/* Équipe Bleue */}
        <div className="team blue">
          <div className="team-header">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="#3B82F6" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              className="lucide lucide-shield h-5 w-5 text-blue-600 mr-2"
            >
              <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" />
            </svg>
            <span className="font-bold">Équipe Bleue</span>
            <div className="team-stats">
              {/*<span className={`team-score ${(!teams[1]?.score || teams[1].score === 0) ? 'pending-score' : ''}`}>
                {teams[1]?.score && teams[1].score !== 0 ? teams[1].score : "En attente"}
              </span>*/}
              <span className="team-elo">ELO: {teams[1]?.teamElo || 1000}</span>
            </div>
          </div>
          <div className="players-list">
            {teams[1]?.players && sortPlayers(teams[1].players).map((player, index) => {
              const playerDetails = getPlayerDetails(player);
              return (
                <div 
                  key={index} 
                  className={`player ${player.isCapitain ? 'captain' : ''}`}
                >
                  <div className="player-info">
                    <span>{playerDetails.nom} {playerDetails.prenom}</span>
                    <span className="player-elo">ELO: {playerDetails.finalElo}</span>
                    {playerDetails.eloChange !== 0 && (
                      <span className={`elo-change ${playerDetails.eloChange > 0 ? 'positive' : 'negative'}`}>
                        ({playerDetails.eloChange > 0 ? '+' : ''}{playerDetails.eloChange})
                      </span>
                    )}
                  </div>
                  {player.isCapitain && <span className="captain-badge">Capitaine</span>}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="score-section">
        <h4>Score final et ELO</h4>
        <div className="score-container">
          <div className="team-score">
            <span>Équipe Noire</span>
            <div className="score-details">
              <span className="score">
                {teams[0]?.score !== undefined ? teams[0].score : "En attente"}
              </span>
              <span className="elo-change">
                {teams[0]?.eloStats?.deltaElo > 0 ? `+${teams[0].eloStats.deltaElo}` : teams[0]?.eloStats?.deltaElo}
              </span>
            </div>
          </div>
          <span className="score-separator">-</span>
          <div className="team-score">
            <span>Équipe Bleue</span>
            <div className="score-details">
              <span className="score">
                {teams[1]?.score !== undefined ? teams[1].score : "En attente"}
              </span>
              <span className="elo-change">
                {teams[1]?.eloStats?.deltaElo > 0 ? `+${teams[1].eloStats.deltaElo}` : teams[1]?.eloStats?.deltaElo}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamCompositionWithScore;