// src/components/UserDashboard.js
import React, { useState } from 'react';
import UserSidebar from './UserSidebar';
//import EventUser from './EventUser';
//import SubscriptionUser from './SubscriptionUser';
//import FormulasUser from './FormulasUser';
import Dashboard from './UserMainDashboard';

import './UserDashboard.css';

const UserDashboard = () => {
    const [activeView, setActiveView] = useState('dashboard');

    const handleShowDashboard = () => setActiveView('dashboard');
    const handleShowSubscriptions = () => setActiveView('subscriptions');
    const handleShowFormulas = () => setActiveView('formulas');
    const handleShowEvents = () => setActiveView('events');


    return (
        <div className="User-dashboard">
            <UserSidebar
                onShowDashboard={handleShowDashboard}
                onShowSubscriptions={handleShowSubscriptions}
                onShowFormulas={handleShowFormulas}
                onShowEvents={handleShowEvents}
                
            />
            <main className="main-content2">
                {activeView === 'dashboard' && <Dashboard/>}
                {/*{activeView === 'subscriptions' && <SubscriptionUser/>}
                {activeView === 'formulas' && <FormulasUser/>}
                {activeView === 'events' && <EventUser />}*/}
               
            </main>
        </div>
    );
};

export default UserDashboard;
