import React, { useState, useEffect } from 'react';
import './SubscriptionManager.css';
import axios from 'axios';

const API_BASE_URL = 'http://localhost:5000/api';

const SubscriptionManager = () => {
    // Ajoutez ces états au début du composant
    const [users, setUsers] = useState([]); // Pour stocker la liste des utilisateurs
    const [searchUser, setSearchUser] = useState(''); // Pour la recherche d'utilisateurs
    const [isNewUser, setIsNewUser] = useState(false); // Pour basculer entre utilisateur existant et nouveau
    const [newUser, setNewUser] = useState({ // Pour les données du nouvel utilisateur
        nom: '',
        email: '',
        telephone: ''
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [subscriptionTypeFilter, setSubscriptionTypeFilter] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [sports, setSports] = useState([]);
    const [formulas, setFormulas] = useState([]);
    const [showNewSubscriptionModal, setShowNewSubscriptionModal] = useState(false);
    const [newSubscription, setNewSubscription] = useState({
        userId: '',
        sportId: '',
        formulaId: '',
        status: 'En attente'
    });
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [subscriptionToEdit, setSubscriptionToEdit] = useState({});
    const [availableFormulas, setAvailableFormulas] = useState([]);

    const statusValues = {
        PENDING: 'En attente',
        ACTIVE: 'Active',
        EXPIRED: 'Expirée',
        UNKNOWN: 'Inconnu',
    };

    useEffect(() => {
        fetchSubscriptions();
        fetchSports();
        fetchFormulas();
        fetchUsers();
    }, []);

    // Ajoutez ce console.log pour vérifier le contenu de users
    useEffect(() => {
        console.log('Users all:', users, 'Type:', typeof users, 'Is Array:', Array.isArray(users));
    }, [users]);

    // Fonction pour récupérer les utilisateurs
    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/users/all`);
            // Si la réponse est un tableau
            if (Array.isArray(response.data)) {
                setUsers(response.data);
            } else {
                // Si la réponse n'est pas un tableau, initialisez avec un tableau vide
                setUsers([]);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs:', error);
            setUsers([]); // Initialiser avec un tableau vide en cas d'erreur
        }
    };


    const fetchSubscriptions = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/subscriptions`);
            setSubscriptions(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des souscriptions:', error);
        }
    };

    const fetchSports = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/sports`);
            setSports(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des sports:', error);
        }
    };

    const fetchFormulas = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/formulas`);
            setFormulas(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des formules:', error);
        }
    };

    const filterFormulasBySport = (sportId) => {
        return formulas.filter(formula => formula.sport._id === sportId);
    };

    const filteredSubscriptions = subscriptions.filter(subscription => {
        const matchesSearch = subscription.user && subscription.user.nom
            ? subscription.user.nom.toLowerCase().includes(searchTerm.toLowerCase())
            : true;

        const matchesStatus = statusFilter
            ? subscription.status === statusFilter
            : true;

        const matchesType = subscriptionTypeFilter
            ? subscription.formula?.title?.toLowerCase() === subscriptionTypeFilter.toLowerCase()
            : true;

        return matchesSearch && matchesStatus && matchesType;
    });

    const handleEdit = async (id) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/subscriptions/${id}`);
            const subscription = response.data;

            console.log("subscription =",subscription);

            const sportFormulas = filterFormulasBySport(subscription.formula.sport._id);

            setSubscriptionToEdit({
                ...subscription,
                availableFormulas: sportFormulas
            });
            setEditModalOpen(true);
        } catch (error) {
            console.error('Erreur lors de la récupération de la souscription:', error);
        }
    };

    const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
        // Logs de vérification des données
        console.log('Données de souscription à éditer:', {
            subscriptionId: subscriptionToEdit._id,
            user: subscriptionToEdit.user,
            formula: subscriptionToEdit.formula,
            sport: subscriptionToEdit.formula?.sport,
            status: subscriptionToEdit.status
        });

        // Vérification des données requises avec logs
        if (!subscriptionToEdit.formula?._id) {
            console.log('Formule manquante:', subscriptionToEdit.formula);
            alert('Veuillez sélectionner une formule');
            return;
        }

        if (!subscriptionToEdit.formula?.sport?._id) {
            console.log('Sport manquant:', subscriptionToEdit.formula?.sport);
            alert('Veuillez sélectionner un sport');
            return;
        }

        if (!subscriptionToEdit.status) {
            console.log('Statut manquant:', subscriptionToEdit.status);
            alert('Veuillez sélectionner un statut');
            return;
        }

        // Préparation des données à envoyer
        const updateData = {
            formulaId: subscriptionToEdit.formula._id,
            status: subscriptionToEdit.status
        };

        console.log('Données envoyées à l\'API:', updateData);

        // Appel API pour mettre à jour la souscription
        const response = await axios.put(`${API_BASE_URL}/subscriptions/${subscriptionToEdit._id}`, updateData);

        console.log('Réponse de l\'API:', response.data);

        if (response.data) {
            setEditModalOpen(false);
            fetchSubscriptions();
            alert('Souscription mise à jour avec succès');
        }

    } catch (error) {
        console.error('Erreur détaillée:', error);
        console.error('Données qui ont causé l\'erreur:', subscriptionToEdit);
        alert(error.response?.data?.message || 'Erreur lors de la mise à jour de la souscription');
    }
};

    const handleCancel = async (id) => {
        try {
            await axios.delete(`${API_BASE_URL}/subscriptions/${id}`);
            fetchSubscriptions();
            console.log(`Souscription ${id} supprimée`);
        } catch (error) {
            console.error('Erreur lors de la suppression de la souscription:', error);
        }
    };

    // Fonction pour gérer la création de la souscription
    const handleCreateSubscription = async () => {
    try {
        let userId = newSubscription.userId;
        
        // Validation des données de souscription
        if (!userId && !isNewUser) {
            throw new Error('Veuillez sélectionner un utilisateur existant');
        }

        if (isNewUser && (!newUser.nom || !newUser.email)) {
            throw new Error('Veuillez remplir les informations du nouvel utilisateur');
        }

        if (!newSubscription.formulaId) {
            throw new Error('Veuillez sélectionner une formule');
        }

        if (!newSubscription.sportId) {
            throw new Error('Veuillez sélectionner un sport');
        }

        // Si c'est un nouvel utilisateur, créer d'abord l'utilisateur
        if (isNewUser) {
            const userResponse = await axios.post(`${API_BASE_URL}/users`, newUser);
            userId = userResponse.data._id;
        }

        // Créer la souscription
        const subscriptionResponse = await axios.post(`${API_BASE_URL}/subscriptions`, {
            user: userId,
            formula: newSubscription.formulaId,
            requestDate: new Date(),
            status: 'En attente'
        });

        console.log('Souscription créée avec succès:', subscriptionResponse.data);

        // Réinitialisation des états
        setShowNewSubscriptionModal(false);
        
        // Rechargement des données
        await Promise.all([
            fetchUsers(),
            fetchSubscriptions()
        ]);

        // Réinitialiser les formulaires
        setNewSubscription({
            userId: '',
            sportId: '',
            formulaId: '',
            status: 'En attente'
        });
        setNewUser({
            nom: '',
            email: '',
            telephone: ''
        });
        setIsNewUser(false);
        setSearchUser('');

        alert('Souscription créée avec succès');

    } catch (error) {
        let errorMessage = 'Une erreur est survenue lors de la création de la souscription';

        if (error.response) {
            errorMessage = error.response.data.message || 
                          error.response.data.error || 
                          `Erreur ${error.response.status}`;
        } else {
            errorMessage = error.message;
        }

        alert(errorMessage);
    }
};
     // Filtrer les utilisateurs
     const filteredUsers = Array.isArray(users) ? users.filter(user => 
        (user.nom && user.nom.toLowerCase().includes(searchUser.toLowerCase())) ||
        (user.email && user.email.toLowerCase().includes(searchUser.toLowerCase()))
    ) : [];

    return (
        <div className="main-content">
            <div className="header">
                <h1>Gestion des Souscriptions</h1>
                <button className="btn btn-primary2" onClick={() => setShowNewSubscriptionModal(true)}>
                    <i className="mdi mdi-plus"></i>
                    Nouvelle souscription
                </button>
            </div>

            <div className="filters">
                <input
                    type="text"
                    className="filter-input"
                    placeholder="Rechercher nom..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
                <select className="filter-input" value={statusFilter} onChange={e => setStatusFilter(e.target.value)}>
                    <option value="">Tous les statuts</option>
                    <option value={statusValues.ACTIVE}>{statusValues.ACTIVE}</option>
                    <option value={statusValues.PENDING}>{statusValues.PENDING}</option>
                    <option value={statusValues.EXPIRED}>{statusValues.EXPIRED}</option>
                </select>
                <select className="filter-input" value={subscriptionTypeFilter} onChange={e => setSubscriptionTypeFilter(e.target.value)}>
                    <option value="">Toutes les formules</option>
                    {Array.from(new Set(subscriptions
                        .filter(sub => sub.formula)
                        .map(sub => sub.formula.title)))
                        .map(title => (
                            <option key={title} value={title}>
                                {title}
                            </option>
                        ))
                    }
                </select>
            </div>

            <table className="subscriptions-table">
                <thead>
                    <tr>
                        <th>Utilisateur</th>
                        <th>Email</th>
                        <th>Sport</th>
                        <th>Formule</th>
                        <th>Date de la demande</th>
                        <th>Montant</th>
                        <th>Statut</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredSubscriptions.map(subscription => (
                        <tr key={subscription._id}>
                            <td>{subscription.user?.nom}</td>
                            <td>{subscription.user?.email}</td>
                            <td>{subscription.formula?.sport?.name}</td>
                            <td>{subscription.formula?.title}</td>
                            <td>{new Date(subscription.requestDate).toLocaleDateString()}</td>
                            <td>{subscription.formula?.price}€</td>
                            <td>
                                <span className={`user-status status-${subscription.status === statusValues.ACTIVE ? 'active' : subscription.status === statusValues.EXPIRED ? 'expired' : subscription.status === statusValues.PENDING ? 'pending' : 'unknown'}`}>
                                            {subscription.status ? subscription.status : statusValues.UNKNOWN}
                                </span>
                            </td>
                            <td>
                                <div className="action-buttons">
                                    <button className="btn btn-sm btn-primary" onClick={() => handleEdit(subscription._id)}>
                                        <i className="mdi mdi-pencil"></i>
                                    </button>
                                    <button className="btn btn-sm btn-danger" onClick={() => handleCancel(subscription._id)}>
                                        <i className="mdi mdi-cancel"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal Nouvelle Souscription */}
            {showNewSubscriptionModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="modal-header">
                            <h2>Nouvelle Souscription</h2>
                            <button className="modal-close" onClick={() => setShowNewSubscriptionModal(false)}>&times;</button>
                        </div>
                        <form onSubmit={handleEditSubmit}>
                            <div className="modal-body">
                                <div className="form-group"><label>Rechercher un utilisateur:</label>
                                    <input
                                        type="text"
                                        value={searchUser}
                                        onChange={(e) => setSearchUser(e.target.value)}
                                        placeholder="Nom ou email..."
                                    />
                                </div>
                                <div className="form-group">
                            
                                        <select
                                            value={newSubscription.userId}
                                            onChange={(e) => setNewSubscription({
                                                ...newSubscription,
                                                userId: e.target.value
                                            })}
                                        >
                                            <option value="">Sélectionner un utilisateur</option>
                                            {filteredUsers.length > 0 ? (
                                                filteredUsers.map(user => (
                                                    <option key={user._id} value={user._id}>
                                                        {user.nom} - {user.email}
                                                    </option>
                                                ))
                                            ) : (
                                                <option value="">Aucun utilisateur trouvé</option>
                                            )}
                                        </select>
                            
                                </div>
                            </div>

                            {/* Section Sport et Formule */}
                            <div className="form-group">
                        
                                    Sport:
                                    <select
                                        value={newSubscription.sportId}
                                        onChange={(e) => {
                                            const sportId = e.target.value;
                                            const sportFormulas = filterFormulasBySport(sportId);
                                            setNewSubscription({
                                                ...newSubscription,
                                                sportId,
                                                formulaId: '',
                                            });
                                            setAvailableFormulas(sportFormulas);
                                        }}
                                    >
                                        <option value="">Sélectionner un sport</option>
                                        {sports.map(sport => (
                                            <option key={sport._id} value={sport._id}>
                                                {sport.name}
                                            </option>
                                        ))}
                                    </select>
                            
                            </div>
                            <div className="form-group">
                    
                                Formule:
                                <select
                                    value={newSubscription.formulaId}
                                    onChange={(e) => setNewSubscription({ ...newSubscription, formulaId: e.target.value })}
                                    disabled={!newSubscription.sportId}
                                >
                                    <option value="">Sélectionner une formule</option>
                                    {availableFormulas.map(formula => (
                                        <option key={formula._id} value={formula._id}>
                                            {formula.title} - {formula.price}
                                        </option>
                                    ))}
                                </select>
                            
                            </div>         
                        

                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary"
                                    onClick={handleCreateSubscription}
                                    disabled={
                                        !newSubscription.userId ||
                                        !newSubscription.sportId ||
                                        !newSubscription.formulaId
                                    }
                                >
                                    Créer
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            )}

            {/* Modal Édition Souscription */}
           {editModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="modal-header">
                            <h2>Éditer la souscription</h2>
                            <button className="modal-close" onClick={() => setEditModalOpen(false)}>&times;</button>
                        </div>
                        <form onSubmit={handleEditSubmit}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Utilisateur:</label>
                                    <input
                                        type="text"
                                        value={subscriptionToEdit.user?.nom || ''}
                                        readOnly
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Sport:</label>
                                    <select
                                        className="form-control"
                                        value={subscriptionToEdit.formula?.sport?._id || ''}
                                        onChange={(event) => {
                                            const sportId = event.target.value;
                                            const sportFormulas = filterFormulasBySport(sportId);

                                            setSubscriptionToEdit(prev => ({
                                                ...prev,
                                                formula: {
                                                    ...prev.formula,
                                                    sport: { _id: sportId }
                                                },
                                                availableFormulas: sportFormulas
                                            }));
                                        }}
                                    >
                                        <option value="">Sélectionner un sport</option>
                                        {sports.map(sport => (
                                            <option key={sport._id} value={sport._id}>
                                                {sport.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Formule:</label>
                                    <select
                                        className="form-control"
                                        value={subscriptionToEdit.formula?._id || ''}
                                        onChange={(event) => {
                                            const formulaId = event.target.value;
                                            const selectedFormula = formulas.find(f => f._id === formulaId);

                                            setSubscriptionToEdit(prev => ({
                                                ...prev,
                                                formula: selectedFormula
                                            }));
                                        }}
                                        disabled={!subscriptionToEdit.formula?.sport?._id}
                                    >
                                        <option value="">Sélectionner une formule</option>
                                        {(subscriptionToEdit.availableFormulas || []).map(formula => (
                                            <option key={formula._id} value={formula._id}>
                                                {formula.title} - {formula.price}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Statut:</label>
                                    <select
                                        className="form-control"
                                        value={subscriptionToEdit.status || ''}
                                        onChange={(event) => setSubscriptionToEdit({
                                            ...subscriptionToEdit,
                                            status: event.target.value
                                        })}
                                    >
                                        <option value={statusValues.ACTIVE}>{statusValues.ACTIVE}</option>
                                        <option value={statusValues.PENDING}>{statusValues.PENDING}</option>
                                        <option value={statusValues.EXPIRED}>{statusValues.EXPIRED}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Éditer</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubscriptionManager;