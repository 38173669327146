import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import './FormulasList.css';

const FormulasList = ({ refreshNotifications })=> {
    const { user } = useAuth();
    const [formulas, setFormulas] = useState([]);
    const [userSubscriptions, setUserSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    useEffect(() => {
      fetchFormulas();
      if (user?._id) {
        fetchUserSubscriptions();
      }
    }, [user?._id]);

  // Ajouter cette fonction pour récupérer les souscriptions de l'utilisateur
  const fetchUserSubscriptions = async () => {
    try {
      const response = await axios.get(`http://localhost:5000/api/subscriptions/user/${user._id}`);
      setUserSubscriptions(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des souscriptions:', error);
    }
  };

  // Ajouter la fonction de souscription
const handleSubscribe = async (formula) => {
  try {
    setIsSubmitting(true);
    
    // Création de la souscription
    const subscriptionData = {
      user: user._id,
      formula: formula._id,
      status: 'En attente'
    };

    await axios.post('http://localhost:5000/api/subscriptions', subscriptionData);
    
    // Création de la notification
    const notificationData = {
      user: user._id,
      type: 'SUBSCRIPTION_REQUEST',
      message: `Votre demande de souscription pour la formule "${formula.title}" a été transmise à l'administrateur. Vous serez notifié lorsqu'elle sera traitée.`,
      status: 'PENDING',
      read: false
    };

    await axios.post('http://localhost:5000/api/notifications', notificationData);
    
    // Rafraîchir les souscriptions et les notifications
    await fetchUserSubscriptions();
    await refreshNotifications(); // Assurez-vous que cette fonction est passée en props depuis UserMainDashboard
    
    // Message de succès
    alert('Demande de souscription envoyée avec succès');
    
  } catch (error) {
    console.error('Erreur lors de la souscription:', error);
    alert('Erreur lors de la demande de souscription');
  } finally {
    setIsSubmitting(false);
  }
};

// Ajouter une fonction pour vérifier si une souscription est en attente
const hasPendingSubscription = (formula) => {
    return userSubscriptions.some(sub => 
      sub.formula._id === formula._id && 
      sub.status === 'En attente'
    );
  };

const fetchFormulas = async () => {
  try {
    const response = await axios.get('http://localhost:5000/api/formulas');
    console.log('Formules non triées:', response.data);

    const sortedFormulas = response.data.sort((a, b) => {
      // Tri par sport
      if (a.sport?.name !== b.sport?.name) {
        return (a.sport?.name || '').localeCompare(b.sport?.name || '');
      }

      // Pour le football
      if (a.sport?.name === 'Football') {
        // Détermine le type basé sur le titre
        const isAnnuelA = a.title.toLowerCase().includes('annuel');
        const isAnnuelB = b.title.toLowerCase().includes('annuel');

        // Si l'un est annuel et l'autre non
        if (isAnnuelA !== isAnnuelB) {
          return isAnnuelA ? -1 : 1; // Annuel en premier
        }

        // Si ce sont des tickets, trier par nombre (du plus grand au plus petit)
        if (!isAnnuelA && !isAnnuelB) {
          const aNumber = parseInt(a.title.match(/\d+/)?.[0] || '0');
          const bNumber = parseInt(b.title.match(/\d+/)?.[0] || '0');
          return bNumber - aNumber; // Inversé l'ordre ici
        }

        return 0;
      } 
      // Pour les autres sports
      else {
        const isAnnuelA = a.title.toLowerCase().includes('annuel');
        const isAnnuelB = b.title.toLowerCase().includes('annuel');

        if (isAnnuelA !== isAnnuelB) {
          return isAnnuelA ? -1 : 1;
        }
        return (a.price || 0) - (b.price || 0);
      }
    });
    
    console.log('Formules triées:', sortedFormulas);
    setFormulas(sortedFormulas);
    setLoading(false);
  } catch (error) {
    console.error('Erreur lors de la récupération des formules:', error);
    setError('Erreur lors de la récupération des formules');
    setLoading(false);
  }
};
  
  // Dans le rendu, modifier la partie des icônes
  const getSportIcon = (sportName) => {
    switch(sportName) {
      case 'Football':
        return 'soccer';
      case 'Padel':
        return 'tennis';
      default:
        return 'sport';
    }
  };

  // Fonction pour vérifier si l'utilisateur a un abonnement annuel actif pour un sport
const hasActiveAnnualSubscription = (sportId) => {
    return userSubscriptions.some(sub => 
      sub.formula.sport._id === sportId && 
      sub.status === 'Active' && 
      sub.formula.title.toLowerCase().includes('annuel')
    );
  };
  
  // Fonction modifiée pour vérifier les souscriptions
  const hasActiveSubscription = (formula) => {
    // Vérifie si l'utilisateur a déjà cette formule active
    const hasThisFormula = userSubscriptions.some(sub => 
      sub.formula._id === formula._id && 
      sub.status === 'Active'
    );
  
    // Vérifie si l'utilisateur a un abonnement annuel actif pour ce sport
    const hasAnnual = hasActiveAnnualSubscription(formula.sport._id);
  
    // Si c'est un ticket et qu'il y a un abonnement annuel actif, désactiver le ticket
    if (!formula.title.toLowerCase().includes('annuel') && hasAnnual) {
      return true;
    }
  
    return hasThisFormula;
  };

  // Grouper les formules par sport avec vérification
  const groupedFormulas = formulas.reduce((acc, formula) => {
    const sportName = formula.sport?.name || 'Autre';
    if (!acc[sportName]) {
      acc[sportName] = [];
    }
    acc[sportName].push(formula);
    return acc;
  }, {});

  if (loading) return <div className="loading">Chargement...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="formulas-container">
      <div className="formulas-header">
        <h2><i className="mdi mdi-ticket-percent"></i> Nos Formules</h2>
      </div>

      {Object.entries(groupedFormulas).map(([sportName, sportFormulas]) => (
        <div key={sportName} className="sport-section">
          <h3 className="sport-title">
          <i className={`mdi mdi-${getSportIcon(sportName)}`}></i>
            {sportName}
          </h3>
          <div className="formulas-grid">
            {sportFormulas.map((formula) => (
              <div key={formula._id} className="formula-card2">
                <div className="formula-header">
                  <h3>{formula.title}</h3>
                  <span className={`formula-type ${(formula.type || '').toLowerCase()}`}>
                    {formula.type}
                  </span>
                </div>

                <div className="formula-content2">
                    <div className="formula-details">
                    <div className="formula-details">
                        <div className="detail-item">
                            <div className="detail-label">
                                <i className="mdi mdi-calendar-range"></i>
                                <span>Date d'expiration:</span>
                            </div>
                            <div className="detail-value">
                                {formula.expiryDate}
                            </div>
                        </div>
                    </div>
                        
                        <div className="detail-item">
                            <div className="detail-label">
                                <i className="mdi mdi-currency-eur"></i>
                                <span>Prix:</span>
                            </div>
                            <div className="detail-value">
                                {formula.price}
                            </div>
                        </div>
                        
                        <div className="detail-item">
                            <div className="detail-label">
                                <i className="mdi mdi-check-circle"></i>
                                <span>Statut:</span>
                            </div>
                            <div className="detail-value">
                                {formula.isActive ? 'Active' : 'Inactive'}
                            </div>
                        </div>
                    </div>

                    <div className="formula-actions">
                        <button 
                            className="subscribe-button"
                            disabled={!formula.isActive || hasActiveSubscription(formula) || hasPendingSubscription(formula) || isSubmitting}
                            onClick={() => handleSubscribe(formula)}
                            >
                            <i className="mdi mdi-cart-plus"></i>
                            {isSubmitting ? 'En cours...' : 
                            !formula.isActive ? 'Non disponible' : 
                            hasPendingSubscription(formula) ? 'En attente de validation' :
                            hasActiveSubscription(formula) ? 
                                (hasActiveAnnualSubscription(formula.sport._id) && !formula.title.toLowerCase().includes('annuel')) ?
                                'Abonnement annuel actif' : 'Déjà souscrit'
                                : 'Souscrire'}
                        </button>
                    </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FormulasList;