import React from 'react';
import './SubscriptionCard.css';

const SubscriptionCard = ({ subscriptions, formatDate, getStatusClassAndIcon }) => {
  return (
    <div className="card subscription-card">
      <div className="card-header">
        <h2 className="card-title">
          <i className="mdi mdi-ticket-account"></i> Mes souscriptions
        </h2>
      </div>
      <ul className="subscription-list">
        {subscriptions.map(subscription => {
          if (!subscription.formula || !subscription.formula.sport) {
            return null;
          }

          const { className, icon } = getStatusClassAndIcon(subscription.status);
          return (
            <li key={subscription._id} className="subscription-item">
              <div className="subscription-info">
                <div className="subscription-header">
                  <div className="sport-info">
                    <i className="mdi mdi-soccer"></i>
                    <h3>{subscription.formula.sport.name}</h3>
                  </div>
                  <span className={`status ${className}`}>
                    <i className={`mdi ${icon} status-icon`}></i>
                    {subscription.status}
                  </span>
                </div>
                <div className="subscription-details">
                  <div className="formula-info">
                    <i className="mdi mdi-ticket"></i>
                    <span>{subscription.formula.title}</span>
                  </div>
                  <div className="date-info">
                    <div className="date-item">
                      <i className="mdi mdi-calendar-start"></i>
                      <span>Début: {formatDate(subscription.requestDate)}</span>
                    </div>
                    <div className="date-item">
                      <i className="mdi mdi-calendar-end"></i>
                      <span>Fin: {subscription.formula.expiryDate}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SubscriptionCard;